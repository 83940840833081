export const getImageCode = `
#########################
# Get Image Code Sample #
#        Python         #
#########################

@app.route('/get-image', methods=['GET'])
def get_image():
    image_path = 'rabbit.jpg'

    # Check if file exists
    if not os.path.exists(image_path):
        return jsonify({"error": "Image not found"}), 404

    try:
        # Get MIME type of the file
        mime_type, _ = mimetypes.guess_type(image_path)
        mime_type = mime_type or 'application/octet-stream'

        # Read and encode the image
        with open(image_path, "rb") as image_file:
            encoded_image = base64.b64encode(image_file.read()).decode('utf-8')

        # Create a response with the encoded image and headers
        response = Response(encoded_image, mimetype=mime_type)
        response.headers['Content-Disposition'] = f'inline; filename="{os.path.basename(image_path)}"'
        return response

    except Exception as e:
        # Return a general error response in case of an exception
        return jsonify({"error": "An internal server error occurred.", "details": "An error has occured; that's all we know."), 500
`;

export const getCallRecordingSamples = `
##########################
# Get Random Data Sample #
#         Python         #
##########################

@app.route('/call-recordings', methods=['GET'])
def get_call_recordings():
    try:
        # Static recordings data
        recordings = {"recordings": [
            {
                "account_sid": "ACXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX",
                "call_sid": "CAXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX1",
                "date_created": "Tue, 12 Apr 2022 10:23:27 +0000",
                "date_updated": "Tue, 12 Apr 2022 10:23:27 +0000",
                "sid": "REXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX1",
                "duration": "45",
                "price": "-0.02000",
                "price_unit": "USD",
                "status": "completed",
                "channels": 1,
                "source": "OutboundAPI",
                "uri": "/2010-04-01/Accounts/ACXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX/Recordings/REXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX1.json",
                "encryption_details": None
            },
            {
                "account_sid": "ACXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX",
                "call_sid": "CAXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX2",
                "date_created": "Wed, 13 Apr 2022 14:40:56 +0000",
                "date_updated": "Wed, 13 Apr 2022 14:40:56 +0000",
                "sid": "REXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX2",
                "duration": "120",
                "price": "-0.05000",
                "price_unit": "USD",
                "status": "completed",
                "channels": 1,
                "source": "InboundAPI",
                "uri": "/2010-04-01/Accounts/ACXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX/Recordings/REXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX2.json",
                "encryption_details": None
            },

        ],
        "page_size": 50,
        "start": 0,
        "uri": "/2010-04-01/Accounts/ACXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX/Recordings.json?PageSize=50",
        "first_page_uri": "/2010-04-01/Accounts/ACXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX/Recordings.json?PageSize=50&Page=0",
        "previous_page_uri": None,
        "next_page_uri": None,
        "page": 0
    }

        # Success response
        return createApiResponse(True, {"recordings": recordings}, [])
    
    except KeyError as e:
        # Handle missing keys in the data
        error_message = f"Key error: {str(e)}"
        return jsonify({"success": False, "error": "Data processing error", "details": "Could not process data"}), 500

    except Exception as e:
        # General exception handling
        return jsonify({"success": False, "error": "Internal Server Error", "details": "An error has occured."}), 500
`;

export const checkIpWhoisSource = `
#########################
# Check IP WHOIS Sample #
#         Python        #
#########################
@app.route('/check-ip-whois', methods=['GET'])
def check_ip_whois():
    try:
        # Get the requesting IP address
        ip_address = request.remote_addr

        ip_check_url = f"http://ip-api.com/json/{ip_address}?fields=status,message,country,countryCode,region,regionName,city,zip,lat,lon,timezone,isp,org,as,mobile,proxy,hosting,query"

        # External API request with timeout
        try:
            response = requests.get(ip_check_url, timeout=5)  # Set a reasonable timeout
            response.raise_for_status()  # Raise an HTTPError for bad responses
            ip_info = response.json()

            # Ensure response contains required data
            if ip_info.get("status") != "success":
                return jsonify({"status": "fail", "message": ip_info.get("message", "Unknown error")}), 400
        except requests.exceptions.Timeout:

            return jsonify({"status": "fail", "message": "Request timed out"}), 504
        except requests.exceptions.RequestException as e:
            return jsonify({"status": "fail", "message": "External service error"}), 502

        return jsonify(ip_info)

    except Exception as e:
        # Catch all unexpected exceptions and log them
        return jsonify({"status": "fail", "message": "Internal server error"}), 500
`;

export const getMapImageSample = `
########################
# Get Map Image Sample #
#       Python         #
########################

def get_map_image_base64(address):
    if not isinstance(address, str) or not address.strip():
        return "Error: Address must be a non-empty string."

    base_url = "https://maps.googleapis.com/maps/api/staticmap?"
    api_key = os.getenv("GOOGLE_MAPS_API_KEY")  # Fetch API key from environment variable

    if not api_key:
        return "Error: API key is missing. Please set the GOOGLE_MAPS_API_KEY environment variable."

    # Construct the request URL
    try:
        markers = f"markers=color:red|label:A|{urllib.parse.quote(address)}"
        size = "size=1024x1024"
        url = f"{base_url}{markers}&{size}&key={api_key}"
    except Exception as e:
        return f"Error constructing the URL: {e}"

    # Fetch the image data
    try:
        response = requests.get(url, timeout=10) 
    except requests.exceptions.RequestException as e:
        return f"Network error while fetching the image: {e}"

    if response.status_code == 200:
        # Ensure response contains an image
        if "image" not in response.headers.get("Content-Type", ""):
            return f"Unexpected content type: {response.headers.get('Content-Type')}"

        # Convert image data to base64
        try:
            encoded_image = base64.b64encode(response.content).decode('utf-8')
            return encoded_image
        except Exception as e:
            return f"Error encoding image to base64: {e}"
    else:
        return f"Error fetching image: HTTP {response.status_code}, {response.text}"

@app.route('/get-map-image', methods=['GET'])
def get_map_image():
    try:
        address = request.args.get('address')
        
        # Check if the address parameter exists
        if not address:
            return jsonify({"error": "Missing 'address' parameter."}), 400
        
        # Validate the length of the address
        if len(address) > 50:
            return jsonify({"error": "Address exceeds the maximum length of 50 characters."}), 400
        
        print(f"Received address: {address}")
        
        # Fetch base64 encoded map image
        image_data = get_map_image_base64(address)
        
        # Handle cases where the function returns None or invalid types
        if not image_data:
            return jsonify({"error": "Failed to fetch map image. Received an empty or None response."}), 500

        if not isinstance(image_data, str):
            return jsonify({"error": "Failed to fetch map image. Invalid data type returned."}), 500
        
        if image_data.startswith("Error"):
            return jsonify({"error": image_data}), 400

        # Prepare the response with the image data
        mime_type = 'image/png'  # Assuming PNG; adjust if needed
        response = Response(image_data, mimetype=mime_type)
        response.headers['Content-Disposition'] = 'inline; filename="map.png"'

        return response
    except Exception as e:
        # Catch any unexpected errors and return a generic error response
        return jsonify({"error": "An unexpected error occurred.", "details": "Could not process the request."}), 500

`;

export const createGiftCertificateSample = `
##############################
# Gift Certificate Generator #
#           Python           #
##############################

def generate_gift_certificate_base64(recipient_name, agent_name, date, amount, certificate_code, template_path):
    try:
        # Load the gift certificate template
        image = Image.open(template_path)
        draw = ImageDraw.Draw(image)
    except Exception as e:
        raise

    try:
        # Define font and size
        font_path = "./micross.ttf" 
        font_size = 39
        font = ImageFont.truetype(font_path, font_size)
    except Exception as e:
        raise

    # Adding text to the image
    positions = {
        "recipient_name": (950, 560),
        "agent_name": (950, 430),
        "amount": (1195, 75),
        "date": (1160, 211),
        "certificate_code": (1508, 640)
    }
    try:
        draw.text(positions["recipient_name"], f"{recipient_name}", fill="black", font=font)
        draw.text(positions["agent_name"], f"{agent_name}", fill="black", font=font)
        draw.text(positions["amount"], f"\${amount}", fill="black", font=font)
        draw.text(positions["date"], f"{date}", fill="black", font=font)
        draw.text(positions["certificate_code"], f"{certificate_code}", fill="black", font=font)
    except Exception as e:
        raise

    try:
        # Convert the modified image to a base64 string
        buffered = BytesIO()
        image.save(buffered, format="PNG")
        encoded_string = base64.b64encode(buffered.getvalue()).decode("utf-8")
        return encoded_string
    except Exception as e:
        raise


@app.route('/create-gift-certificate', methods=['POST'])
def create_gift_certificate():
    try:
        # Parse request JSON
        data = request.get_json()
        if not data:
            return jsonify({"error": "Request body must be JSON"}), 400

        # Validate recipient and sender names
        recipient_name = data.get('recipient_name', '').strip()
        agent_name = data.get('sender_name', '').strip()

        if len(recipient_name) > 50 or len(agent_name) > 50:
            return jsonify({"error": "Recipient or sender name must not exceed 50 characters"}), 400
        if not recipient_name or not agent_name:
            return jsonify({"error": "Recipient and sender name must not be empty"}), 400

        # Validate amount
        amount = data.get('amount')
        try:
            amount = float(amount)
            if amount <= 0 or amount > 1_000_000:
                raise ValueError("Invalid amount value")
            # Format amount as currency
            amount = f"{amount:.2f}"
        except (ValueError, TypeError):
            return jsonify({"error": "Invalid amount; must be a positive number not exceeding 1,000,000"}), 400

        # Get current date
        date = datetime.now().strftime("%Y-%m-%d") 
        # Generate a certificate code
        certificate_code = "xE3jD" 
        template_path = "./gcTemplate.png"

        # Generate the gift certificate image
        encoded_image = generate_gift_certificate_base64(recipient_name, agent_name, date, amount, certificate_code, template_path)
        if not encoded_image:
            return jsonify({"error": "Error generating gift certificate"}), 500

        # Return the generated certificate as a response
        mime_type = 'image/png'  
        response = Response(encoded_image, mimetype=mime_type)
        response.headers['Content-Disposition'] = 'inline; filename="gift_certificate.png"'

        return response

    except Exception as e:
        # Catch-all for unexpected errors
        return jsonify({"error": "An unexpected error occurred", "details": str(e)}), 500
`;

export const validatePasswordSample = `
######################
# Password Validator #
#       Python       #
######################

@app.route('/validate-password', methods=['POST'])
def validate_password():
    try:
        # Parse and validate request data
        data = request.get_json()
        if not data or not isinstance(data, dict):
            raise BadRequest("Invalid input format. Expected a JSON object.")

        password = data.get('password', '').strip()
        if not password:
            return createApiResponse(
                False, {}, ["Password cannot be empty."]
            ), 400

        # Define password validation rules
        errors = []
        if len(password) > 30:
            errors.append("Password must not be longer than 30 characters.")
        if len(password) < 8:
            errors.append("Password must be at least 8 characters long.")
        if not any(char.isupper() for char in password):
            errors.append("Password must contain at least one uppercase letter.")
        if not any(char.islower() for char in password):
            errors.append("Password must contain at least one lowercase letter.")
        if not any(char.isdigit() for char in password):
            errors.append("Password must contain at least one digit.")
        if not any(char in "!@#$%^&*()-_=+" for char in password):
            errors.append("Password must contain at least one special character (!@#$%^&*()-_=+).")
        if " " in password:
            errors.append("Password must not contain spaces.")

        # Return validation result
        if errors:
            return createApiResponse(
                False, {}, errors
            ), 400

        return createApiResponse(
            True, {"password_valid": True}, []
        )
    except BadRequest as e:
        return createApiResponse(False, {}, [str(e)]), 400
    except Exception as e:
        # Catch-all for unexpected errors
        return createApiResponse(False, {}, ["An unexpected error occurred."]), 500

`;

export const sampleFormsSample = `
###################################
# Return Dangerous Input Received #
#              Python             #
###################################

@app.route('/sample-forms', methods=['POST'])
def validate_phone_number():
    try:
        # Parse and validate JSON input
        data = request.get_json()
        if not data or not isinstance(data, dict):
            raise BadRequest("Invalid input format. Expected a JSON object.")

        # Sanitize and validate each input field
        sanitized_data = {}
        for key, value in data.items():
            if not isinstance(key, str):
                raise BadRequest("All keys must be strings.")
            if not isinstance(value, (str, int, float, bool, type(None))):
                raise BadRequest(f"Unsupported value type for key '{key}'.")
            if isinstance(value, str):
                # Strip leading/trailing whitespace and escape HTML special characters
                sanitized_value = value.strip().replace('<', '&lt;').replace('>', '&gt;')
                # Enforce length constraints
                if len(sanitized_value) > 500:
                    raise BadRequest(f"The value for '{key}' exceeds the maximum length of 500 characters.")
                sanitized_data[key] = sanitized_value
            else:
                # Directly include non-string values (additional checks can be added if needed)
                sanitized_data[key] = value

        return createApiResponse(True, sanitized_data, [])
    
    except BadRequest as e:
        return createApiResponse(False, {}, [str(e)]), 400
    except Exception as e:
        # Handle unexpected errors
        return createApiResponse(False, {}, ["An error occurred while processing your request."]), 500
`;

export const changeSiteNameSample = `
######################################
# Change Site Name With React Action #
#               Python               #
######################################

@app.route('/change-site-name', methods=['PATCH'])
def change_site_name():
    try:
        # Parse JSON input
        data = request.get_json()
        if not data or not isinstance(data, dict):
            raise BadRequest("Invalid input format. Expected a JSON object.")

        # Get and sanitize site_name input
        site_name = data.get('site_name', '')
        if not isinstance(site_name, str):
            raise BadRequest("The 'site_name' must be a string.")
        
        # Escape HTML and limit length to 50 characters
        safe_site_name = escape(site_name.strip()[:50])

        # Log sanitized value securely
        app.logger.info("Site name change request received: %s", safe_site_name)

        return createApiResponse(
            True,
            {"message": "Success! The website name has been changed."},
            {"change_site_name": safe_site_name}
        )

    except BadRequest as e:
        return createApiResponse(False, {}, [str(e)]), 400
    except Exception as e:
        app.logger.error("Unexpected error: %s", str(e))
        return createApiResponse(False, {}, ["An error occurred while processing your request."]), 500
`;

export const breakdownSample = `
///////////////////////////////////
// Site Deletion React Component //
//             React             //
///////////////////////////////////

import React, { useState, useEffect, useRef } from "react";
import { Box, Typography, Container, Fab } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close"; 
import { APIUrl } from "../settings";

const Breakdown = ({ setIsBreaking }) => {
  const [isBreakingDown, setIsBreakingDown] = useState(false);
  const [countdown, setCountdown] = useState(10);
  const [displayedText, setDisplayedText] = useState("");
  const [messageIndex, setMessageIndex] = useState(0);
  const [charIndex, setCharIndex] = useState(0);
  const [apiResponse, setApiResponse] = useState(null);
  const [messages, setMessages] = useState([]);
  const messagesEndRef = useRef(null);


  const now = new Date();
  const time9 = new Date(now.getTime() - 9 * 60 * 1000);
  const options = { year: "numeric", month: "long", day: "numeric", hour: "numeric", minute: "numeric", hour12: true };
  const time9MinAgo = time9.toLocaleString("en-US", options);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(\`\${APIUrl}/check-ip-whois\`);
        if (!response.ok) {
          throw new Error(\`HTTP error! status: \${response.status}\`);
        }
        const data = await response.json();
        setApiResponse(data);
        const attackerInfo = \`
<system>Attacker Information:</system>\\n
<system>ISP: \${data.isp}</system>\\n
<system>Region Name: \${data.regionName}</system>\\n
<system>Country Code: \${data.countryCode}</system>\\n
<system>User Agent: \${navigator.userAgent}</system>\\n
<system>Latitude: \${data.lat}</system>\\n
<system>Longitude: \${data.lon}</system>\\n
<system>Organization: \${data.org}</system>\\n
<system>Proxy: \${data.proxy}</system>\\n
<system>Timezone: \${data.timezone}</system>\\n
<user>...</user>\\n
<system>Incident report created.</system>\\n
<system>Securing system.</system>\\n
<system>System logging off.</system>\\n
\`;
        const initialSimulationCommands = \`
<system>root login initiated...</system>\\n
<system>Welcome, root.</system>\\n
<root># rm -rf /var/www/matthewkontos.com</root>\\n
<system>Directory /var/www/matthewkontos.com deleted.</system>\\n\\n
<root># mysql</root>\\n
<user>mysql> SHOW DATABASES;</user>\\n
+--------------------+\\n
| Database           |\\n
+--------------------+\\n
| information_schema |\\n
| matthewkontos.com  |\\n
| mysql              |\\n
| performance_schema |\\n
| sys                |\\n
+--------------------+\\n
5 rows in set (0.00 sec)\\n\\n
<user>mysql> USE \\\`matthewkontos.com\\\`;</user>\\n
Database changed\\n\\n
<user>mysql> DROP TABLE projects, blog_posts, testimonials, contact_messages;</user>\\n
Query OK, 0 rows affected (0.10 sec)\\n\\n
<user>mysql> DROP DATABASE \\\`matthewkontos.com\\\`;</user>\\n
Query OK, 1 row affected (0.15 sec)\\n\\n
<user>mysql> SHOW DATABASES;</user>\\n
+--------------------+\\n
| Database           |\\n
+--------------------+\\n
| information_schema |\\n
| mysql              |\\n
| performance_schema |\\n
| sys                |\\n
+--------------------+\\n
4 rows in set (0.00 sec)\\n\\n
<root># apt-get purge --auto-remove apache2 mysql-server -y</root>\\n
Reading package lists... Done\\n
Building dependency tree\\n
Reading state information... Done\\n
The following packages will be REMOVED:\\n
apache2* mysql-server*\\n
0 upgraded, 0 newly installed, 2 to remove and 0 not upgraded.\\n
After this operation, 457MB of disk space will be freed.\\n
(Reading database ... Done)\\n
Removing apache2 (2.4.62)...\\n
Removing mysql-server (8.0.36)...\\n
Purging configuration files for apache2 and mysql-server...\\n
<root># sudo rm -rf /</root>\\n
<system>Warning: This action will delete all data on this system.</system>\\n
<system>Proceeding with system-wide deletion...</system>\\n
<system>Deleting...</system>\\n
<system>System error: Critical processes terminated. System crash imminent.</system>\\n
<user>...</user>\\n
<user>......</user>\\n
<user>............</user>\\n
<user>...............</user>\\n
<system>System Automatic Backup Restoring to last backup taken 9 minutes ago at: \${time9MinAgo}</system>\\n
<user>...</user>\\n
<user>......</user>\\n
<user>............</user>\\n
<system>Server matthewkontos.com has been fully restored to the last backup created \${time9MinAgo}.</system>\\n
<system>Getting Attacker Information.</system>\\n
<user>...</user>\\n
<user>......</user>\\n
<user>............</user>\\n
\`;
        setMessages(initialSimulationCommands.split("\\n").concat(attackerInfo.split("\\n")));
      } catch (error) {
        console.error("Failed to fetch data:", error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const startBreakdown = () => {
      setIsBreakingDown(true);
    };
    startBreakdown();
    if (isBreakingDown) {
      const interval = setInterval(() => {
        setCountdown((prevCount) => (prevCount > 0 ? prevCount - 1 : 0));
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [isBreakingDown]);

  useEffect(() => {
    if (messageIndex < messages.length) {
      const currentMessage = messages[messageIndex];
      if (
        currentMessage.startsWith("<root>") ||
        currentMessage.startsWith("<user>") ||
        currentMessage.startsWith("<system>")
      ) {
        const strippedMessage = currentMessage.replace(/<[^>]*>/g, "");
        if (charIndex < strippedMessage.length) {
          const typingTimeout = setTimeout(() => {
            setDisplayedText((prev) => prev + strippedMessage[charIndex]);
            setCharIndex((prev) => prev + 1);
          }, 50);
          return () => clearTimeout(typingTimeout);
        } else {
          setCharIndex(0);
          setMessageIndex((prev) => prev + 1);
          setDisplayedText((prev) => prev + "\\n");
        }
      } else {
        setDisplayedText((prev) => prev + currentMessage + "\\n");
        setMessageIndex((prev) => prev + 1);
      }
    }
  }, [messageIndex, charIndex, messages]);

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [displayedText]);

  const styles = {
    container: {
      width: "100vw",
      height: "100vh",
      position: "fixed",
      top: 0,
      left: 0,
      zIndex: 9999,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      backgroundColor: "black",
      color: "lime",
      fontFamily: \`'Press Start 2P', monospace\`,
      fontSize: "1rem",
      textAlign: "left",
      whiteSpace: "pre-wrap",
      overflowY: "auto",
      pointerEvents: "auto",
    },
    content: {
      maxHeight: "90vh",
      overflowY: "auto",
      width: "100%",
    },
  };

  return (
    <div style={styles.container}>
      <Container style={styles.content}>
        <Typography
          component="pre"
          style={{
            color: "lime",
            whiteSpace: "pre-wrap",
            wordWrap: "break-word",
            overflowWrap: "break-word",
          }}
        >
          {displayedText}
        </Typography>
        <div ref={messagesEndRef}></div>
      </Container>
        <Fab
          color="error"
          aria-label="close"
          style={{ position: "fixed", bottom: 16, right: 16 }}
          onClick={() => setIsBreaking(false)}
        >
          <CloseIcon />
        </Fab>
    </div>
  );
};

export default Breakdown;

`;

export const APIExplorerSample = `
import React, { useState } from "react";
import {
  Typography,
  Box,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Chip,
  Modal,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import APICaller from "../components/APICaller";
import {

  createGiftCertificateSample,
} from "../sourceCodeSamples/sourceCodeSamples";
import APIExplorerComponents from "../components/APIExplorerComponents";
import {accordionData} from "../sourceCodeSamples/accordionData";


const methodColors = {
  GET: "#2196f3",
  POST: "#4caf50",
  PATCH: "#ff9800",
  DELETE: "#f44336",
};

export default function APIExplorer({ setSiteName, setIsBreakingDown }) {
  const [selectedAPI, setSelectedAPI] = useState({
    method: "POST",
    endpoint: "/create-gift-certificate",
    details:
      "Creates a sample gift certificate. A template image is loaded and text is superimposed onto the image.",
    inputs: [
      {
        type: "text",
        label: "recipient_name",
        defaultValue: "Satisfied Customer",
      },
      {
        type: "text",
        label: "sender_name",
        defaultValue: "Qualified Sender",
      },
      {
        type: "text",
        label: "amount",
        defaultValue: "50.00",
      },
    ],
    sourceCodeLanguage: "python",
    sourceCodeSample: createGiftCertificateSample,
  });

  const [openModal, setOpenModal] = useState(false);

  return (
    <Box sx={{ padding: 3 }}>
      <Typography variant="h4" gutterBottom>
        API Explorer
        <Chip
          label="View Source"
          onClick={() => setOpenModal(true)}
          sx={{ ml: 2, cursor: "pointer" }}
        />
      </Typography>
      <Typography variant="body1" paragraph>
        API Calls are used to communicate with the server and interact with
        data.
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={12} md={3}>
          <Box sx={{ border: "1px solid #ccc", padding: 2 }}>
            <Typography variant="h6" style={{ marginBottom: "15px" }}>
              Request Methods
            </Typography>
            {accordionData.map((item, index) => (
              <Accordion key={index}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={\`panel\${index}-content\`}
                  id={\`panel\${index}-header\`}
                >
                  <Grid container alignItems="center" spacing={1}>
                    <Grid item>
                      <Chip
                        label={item.title}
                        sx={{
                          backgroundColor: methodColors[item.title] || "#000",
                          color: "white",
                          fontWeight: "bold",
                        }}
                      />
                    </Grid>
                    <Grid item xs>
                      <Typography>{item.description}</Typography>
                    </Grid>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails>
                  {item.content.map((contentItem, contentIndex) => (
                    <Box
                      key={contentIndex}
                      sx={{
                        padding: 1,
                        "&:hover": {
                          backgroundColor: "#e0e0e0",
                          cursor: "pointer",
                        },
                        transition: "background-color 0.3s ease",
                        marginBottom: 1,
                      }}
                      onClick={() => {
                        setSelectedAPI({
                          method: item.title,
                          endpoint: contentItem.endpoint,
                          details: contentItem.details || "",
                          inputs: contentItem.inputs || [],
                          sourceCodeSample: contentItem.sourceCodeSample || "",
                          sourceCodeLanguage:
                            contentItem.sourceCodeLanguage || "",
                        });
                      }}
                    >
                      <Typography>{contentItem.title}</Typography>
                    </Box>
                  ))}
                </AccordionDetails>
              </Accordion>
            ))}
          </Box>
        </Grid>
        <Grid item xs={12} md={9}>
          <Box sx={{ border: "1px solid #ccc", padding: 2 }}>
            <APICaller
              method={selectedAPI.method}
              endpoint={selectedAPI.endpoint}
              details={selectedAPI.details}
              inputs={selectedAPI.inputs}
              setSiteName={setSiteName}
              setIsBreakingDown={setIsBreakingDown}
              sourceCodeLanguage={selectedAPI.sourceCodeLanguage}
              sourceCodeSample={selectedAPI.sourceCodeSample}
            />
          </Box>
        </Grid>
      </Grid>
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <Box
          sx={{
            width: "80%",
            bgcolor: "background.paper",
            borderRadius: 2,
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography variant="h6" component="h2" gutterBottom>
            API Explorer Components
          </Typography>
          <Typography variant="body1">
            These are some of the React components used to make this API Explorer. Each component is made with built-in styling for demonstration.
          </Typography>
          <APIExplorerComponents />
        </Box>
      </Modal>
    </Box>
  );
}
`;

export const APICallerSample = `
import React, { useState, useEffect } from "react";
import {
  Typography,
  Box,
  Grid,
  Button,
  ButtonGroup,
  TextField,
  Tabs,
  Tab,
  LinearProgress,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  Checkbox,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import HTTPResponse from "./HTTPResponse";
import { APIUrl } from "../settings";
import CodeSnippet from "./CodeSnippet";

export default function APICaller({
  setSiteName,
  setIsBreakingDown,
  method,
  endpoint,
  details,
  inputs = [],
  sourceCodeLanguage,
  sourceCodeSample,
}) {
  // Ensure state initialization with default values
  const [inputValues, setInputValues] = useState(() =>
    inputs.reduce((acc, input) => {
      acc[input.label] =
        input.defaultValue !== undefined
          ? input.defaultValue
          : input.type === "checkbox"
          ? false
          : "";
      return acc;
    }, {})
  );

  const [activeTab, setActiveTab] = useState(0);
  const [responseVisible, setResponseVisible] = useState(false);
  const [apiResponse, setApiResponse] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [serverResponseTime, setServerResponseTime] = useState(null);

  useEffect(() => {
    // Hide the response whenever a new endpoint is selected
    setResponseVisible(false);
  }, [endpoint]);

  
  useEffect(() => {
    setInputValues(
      inputs.reduce((acc, input) => {
        acc[input.label] =
          input.defaultValue !== undefined
            ? input.defaultValue
            : input.type === "checkbox"
            ? false
            : "";
        return acc;
      }, {})
    );
  }, [inputs]);

  const handleInputChange = (label, value) => {
    setInputValues({ ...inputValues, [label]: value });
  };

  const handleSendRequest = async () => {
    setIsLoading(true);
    const startTime = Date.now();

    try {
      let fullUrl = \`\${APIUrl}\${endpoint}\`;

      // Construct query parameters if method is GET
      if (method === "GET" && inputs.length > 0) {
        const queryParams = new URLSearchParams();
        for (const [key, value] of Object.entries(inputValues)) {
          if (value !== "" && value !== undefined) {
            // Exclude empty values
            queryParams.append(key, value);
          }
        }
        if (queryParams.toString()) {
          fullUrl += \`?\${queryParams.toString()}\`;
        }
      }

      const options = {
        method,
        headers: {
          "Content-Type": "application/json",
        },
        body: method !== "GET" ? JSON.stringify(inputValues) : null,
      };

      const response = await fetch(fullUrl, options);
      const endTime = Date.now();
      setServerResponseTime(endTime - startTime);

      let responseBody = null;
      if (response.headers.get("Content-Type")?.includes("application/json")) {
        responseBody = await response.json();
      } else {
        responseBody = await response.text();
      }

      // Check if responseBody is an object and has the expected structure
      if (
        typeof responseBody === "object" &&
        responseBody !== null &&
        responseBody.actions
      ) {
        if (responseBody.actions.change_site_name) {
          console.log("here");
          setSiteName(responseBody.actions.change_site_name);
        }
        if (responseBody.actions.delete_website) {
          setIsBreakingDown(true);
        }
      }

      setApiResponse({
        status: {
          code: response.status,
          text: response.statusText,
        },
        headers: {
          "Content-Type": response.headers.get("Content-Type"),
          "Cache-Control": response.headers.get("Cache-Control"),
        },
        body:
          typeof responseBody === "string"
            ? responseBody
            : JSON.stringify(responseBody, null, 2),
      });
      setResponseVisible(true);
      setError(null);
    } catch (err) {
      setError(\`Error: \${err.message}\`);
      setApiResponse(null);
      setResponseVisible(true);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center" gap={2}>
      <ButtonGroup variant="contained" aria-label="API method buttons">
        <Button color="primary" disabled={method !== "GET"}>
          GET
        </Button>
        <Button color="success" disabled={method !== "POST"}>
          POST
        </Button>
        <Button color="warning" disabled={method !== "PATCH"}>
          PATCH
        </Button>
        <Button color="error" disabled={method !== "DELETE"}>
          DELETE
        </Button>
      </ButtonGroup>
      <div>{details}</div>

      <Grid container spacing={2} alignItems="center">
        {/* Always display the endpoint text field */}
        <Grid item xs={12} md={6}>
          <TextField
            label="Endpoint"
            variant="outlined"
            fullWidth
            value={APIUrl + endpoint}
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>

        {/* Render inputs dynamically if provided */}
        {inputs.length > 0 &&
          inputs.map((input) => {
            switch (input.type) {
              case "text":
                return (
                  <Grid item xs={12} key={input.label}>
                    <TextField
                      label={input.label}
                      variant="outlined"
                      fullWidth
                      value={inputValues[input.label] || ""}
                      onChange={(e) =>
                        handleInputChange(input.label, e.target.value)
                      }
                    />
                  </Grid>
                );
              case "radio":
                return (
                  <Grid item xs={12} key={input.label}>
                    <FormControl component="fieldset">
                      <Typography>{input.label}</Typography>
                      <RadioGroup
                        value={inputValues[input.label] || ""}
                        onChange={(e) =>
                          handleInputChange(input.label, e.target.value)
                        }
                      >
                        {input.options.map((option) => (
                          <FormControlLabel
                            key={option.value}
                            value={option.value}
                            control={<Radio />}
                            label={option.label}
                          />
                        ))}
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                );
              case "checkbox":
                return (
                  <Grid item xs={12} key={input.label}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={!!inputValues[input.label]}
                          onChange={(e) =>
                            handleInputChange(input.label, e.target.checked)
                          }
                        />
                      }
                      label={input.label}
                    />
                  </Grid>
                );
              case "dropdown":
                return (
                  <Grid item xs={12} key={input.label}>
                    <FormControl fullWidth>
                      <InputLabel>{input.label}</InputLabel>
                      <Select
                        value={inputValues[input.label] || ""}
                        onChange={(e) =>
                          handleInputChange(input.label, e.target.value)
                        }
                      >
                        {input.options.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                );
              default:
                return null;
            }
          })}

        <Grid item xs={12} sm={4}>
          <Button
            variant="contained"
            startIcon={<SendIcon />}
            fullWidth
            onClick={handleSendRequest}
          >
            Send Request
          </Button>
        </Grid>
      </Grid>

      {isLoading && (
        <Box width="100%">
          <LinearProgress />
        </Box>
      )}

      {responseVisible && (
        <>
          <Tabs
            value={activeTab}
            onChange={(e, newValue) => setActiveTab(newValue)}
            aria-label="response tabs"
          >
            <Tab label="Response Preview" />
            <Tab label="Endpoint Source Code" />
          </Tabs>
          {activeTab === 0 && (
            <Box p={2} width="100%">
              {apiResponse ? (
                <HTTPResponse
                  status={apiResponse.status}
                  headers={apiResponse.headers}
                  body={apiResponse.body}
                  serverResponseTime={serverResponseTime}
                />
              ) : (
                <Typography color="error">{error}</Typography>
              )}
            </Box>
          )}

          {activeTab === 1 && (
            <Box p={2} width="100%">
              <div style={{ margin: "15px" }}>
                This is the API data logic. Note that rate limiting, logging,
                and other security features are not shown.
              </div>

              <CodeSnippet
                language={sourceCodeLanguage}
                code={sourceCodeSample}
              />
            </Box>
          )}
        </>
      )}
    </Box>
  );
}

`;

export const websocketVoicemailCode = `
  ws.on("message", function incoming(message, hostUrl) {
    const msg = JSON.parse(message);

    switch (msg.event) {
      case "connected":
        console.log(\`A new call has connected.\`);
        // Create Stream to the Google Speech to Text API
        recognizeStream = client
          .streamingRecognize(request)
          .on("error", console.error)
          .on("data", (data) => {
            let isFinalInterimResult = data.results[0].isFinal;
            currentInterimResult = data.results[0].alternatives[0].transcript;
            console.log(
              \`Call Sid: \${callSid} | \${data.results[0].alternatives[0].transcript}\`
            );

            if (isFinalInterimResult) {
              //create the conversation array
              currentConversationArray.push(currentInterimResult);
              //console.log(JSON.stringify(currentConversationArray));
            }
          });
        break;
      case "start":
        console.log(\`Starting Media Stream \${msg.streamSid}\`);
        console.log(\`Setting callSid to \${msg.start.callSid}\`);
        callSid = msg.start.callSid;
        updateDbCallStatus(callSid, 'Call WS Started...')
        console.log(\`Set callSid to \${callSid}\`);
        mediaStreamSaver = new TwilioMediaStreamSaveAudioFile({
          saveLocation: __dirname + "/recordings",
          saveFilename: \`\${callSid}\`,
          onSaved: () => console.log("File was saved!"),
        });

        mediaStreamSaver.twilioStreamStart();
        break;

      case "media":
        // Write Media Packets to the recognize stream
        if (recognizeStream && recognizeStream.writable) {
          recognizeStream.write(msg.media.payload);
        } else {
          console.log("Stream is not writable.");
          // Handle the logic to restart or end the process
        }
        let chunkNumber = msg.media.chunk;
        //check time related things
        if (checkAbsoluteTimeout(timeCallConnected, absoluteTimeout)) {
          //hangup the call
          console.log(
            \`Call Sid: \${callSid} exceeded the \${absoluteTimeout} second Absolute Timeout...\`
            
          );
          updateDbCallStatus(callSid, \`Call Sid: \${callSid} exceeded the \${absoluteTimeout} second Absolute Timeout...\`)
          hangUpCall(callSid);
        }
        //only perform if conversation isnt solved
        if (!dtmfPressed || !conversationSolved) {
          if (
            currentConversationArray.join("") == "" &&
            timeElapsed(conversationAnsweredTime, noGreetingTimeout)
          ) {
            console.log("Answered didnt say anything");
            updateDbCallStatus(callSid, \`Answered didnt say anything\`);
            hangUpCall(callSid);
          }
          //check if its short
          if (
            countWords(currentConversationArray.join("")) <
              minimumGreetingWordCount &&
            timeElapsed(conversationAnsweredTime, minimumGreetingCheckTime) &&
            !conversationSolved
          ) {
            console.log("not enough words in too much time. shutting down");
            updateDbCallStatus(callSid, \`not enough words in too much time. shutting down\`);
            hangUpCall(callSid);
          }
        }

        if (mediaStreamSaver) {
          mediaStreamSaver.twilioStreamMedia(msg.media.payload);
        }

        //this is the main execution.
        //check to see if were on the "main level"
        //reset to main after pressing a dtmf digit

        //check its a person
        if (isHumanAnswer(currentConversationArray.join(""))) {
          console.log("This has human phrases in it. shutting down.");
          updateDbCallStatus(callSid, \`This has human phrases in it. shutting down.\`);
          hangUpCall(callSid);
          conversationSolved = true;
        }

        //check if supposed to leave VM here
        if (
          determineIfIsVoicemail(currentInterimResult) ||
          determineIfIsVoicemail(currentConversationArray.join(""))
        ) {
          if (!conversationSolved) {
            updateDbCallStatus(callSid, \`We're supposed to leave a voicemail here\`);
            console.log("We're supposed to leave a voicemail here");
          }
          //leave voicemail
          conversationSolved = true;
          leaveVoicemailHere = true;

          // wait for silence
          processAudio(msg.media.payload, chunkNumber)
            .then((response) => {
              if (!response.data.exceeds_threshold) {
                //console.log("here for silence!");
                consecutiveSilentChunks++;
                //console.log(consecutiveSilentChunks);
                if (consecutiveLoudChunks < falsePositiveThreshold) {
                  //console.log("Still under threshold...")
                  if (silentSince === null) {
                    silentSince = Date.now();
                    //console.log(\`First Silence: \${silentSince}\`);
                  } else {
                    const now = Date.now();
                    //console.log(\`\${now} : Silent Since: \${silentSince} : Difference: \${now - silentSince}\`);
                    if (now - silentSince >= silenceThreshold) {
                      console.log(
                        \`Detected silence for \${silenceThreshold} milliseconds!\`
                      );

                      ///were here because silence was waited for
                      //do something now
                      if (leaveVoicemailHere && !startedLeavingMessage) {
                        startedLeavingMessage = true;
                        console.log(\`playing audio for \${callSid}\`);

                        //ultra temporarily get random number and play audio
                        let randomNumber =
                          Math.floor(Math.random() * (32 - 1 + 1)) + 1;
                        let googleBucketUrl = \`https://storage.googleapis.com/mwlorealtorrecordings/\${randomNumber}.wav\`;
                        updateDbCallStatus(callSid, \`Leaving Voicemail Message for\`);
                        updateDbMessagePlayed(callSid);
                        playAudio(callSid, googleBucketUrl);
                      }
                      silentSince = null;
                      consecutiveSilentChunks = 0;
                      consecutiveLoudChunks = 0;
                    }
                  }
                }
                consecutiveLoudChunks = 0;
              } else {
                // Reset since the chunk is not silent
                silentSince = null;
                consecutiveSilentChunks = 0;
                consecutiveLoudChunks++;
              }
            })
            .catch((error) => {
              console.error("Error processing audio:", error);
              // Reset on error as well
              silentSince = null;
              consecutiveSilentChunks = 0;
            });
        }

        //check if there are DTMF tones to press
        if (!dtmfPressed) {
          dtmfFound = findDTMFDigit(
            currentConversationArray.join(""),
            presumptiousMode
          );
          interimDtmfFound = findDTMFDigit(
            currentInterimResult,
            presumptiousMode
          );
        }

        if (dtmfFound && !dtmfPressed) {
          console.log("Found the key to press; it's: " + dtmfFound);
          currentConversation = currentConversationArray.join("");
          sendDtmfTone(callSid, dtmfFound);
          updateDbDtmfPressed(callSid, dtmfFound);
          updateDbCallStatus(callSid, \`Pressed DTMF\`);
          //reset the conversation log/other state variables
          currentConversation = "";
          currentInterimResults = [];
          currentInterimResult = "";
          currentConversationArray = [];
          conversationSolved = false;
          silentSince = null;
          consecutiveSilentChunks = 0;
          consecutiveLoudChunks = 0;
          leaveVoicemailHere = false;
          startedLeavingMessage = false;
          dtmfPressed = true;
          dtmfPressed = true;
          dtmfFound = false;
          interimDtmfFound = false;
          
        }
        if (interimDtmfFound && !dtmfPressed) {
          console.log("Found the key to press; it's: " + interimDtmfFound);
          sendDtmfTone(callSid, interimDtmfFound);
          updateDbDtmfPressed(callSid, interimDtmfFound);
          updateDbCallStatus(callSid, \`Pressed Interim DTMF\`);
          //reset the conversation log/other state variables
          currentConversation = currentConversationArray.join("");
          currentInterimResults = [];
          currentInterimResult = "";
          currentConversationArray = [];
          conversationSolved = false;
          silentSince = null;
          consecutiveSilentChunks = 0;
          consecutiveLoudChunks = 0;
          leaveVoicemailHere = false;
          startedLeavingMessage = false;
          dtmfPressed = true;
          dtmfFound = false;
          interimDtmfFound = false;
          
        }

        break;

      case "stop":
        console.log(\`Call Has Ended\`);
        recognizeStream.destroy();
        mediaStreamSaver.twilioStreamStop();
        if (!conversationSolved) {
          //log the conversation
          fs.appendFile(
            "./unsolvedConversations.html",
            "<li>" + currentConversationArray.join("") + "<li><br><br>",
            (err) => {
              if (err) {
                console.error("An error occurred:", err);
              }
              console.log("Content appended successfully!");
            }
          );
        }
        saveConversationText(
          currentConversationArray.join(""),
          conversationAnsweredTime,
          callSid
        );
        updateDbTranscription(callSid, currentConversation + " " + currentConversationArray.join(""));
        updateDbCallFinished(callSid);



        break;
    }
  });
`;
