export const giftCertificate = `
C:\\>ab -n 100000 -c 5 -p gift-certificate.json -T application/json http://127.0.0.1:8080/create-gift-certificate
This is ApacheBench, Version 2.3 <$Revision: 1903618 $>
Copyright 1996 Adam Twiss, Zeus Technology Ltd, http://www.zeustech.net/
Licensed to The Apache Software Foundation, http://www.apache.org/

Benchmarking 127.0.0.1 (be patient)
Completed 10000 requests
Completed 20000 requests
Completed 30000 requests
Completed 40000 requests
Completed 50000 requests
Completed 60000 requests
Completed 70000 requests
Completed 80000 requests
Completed 90000 requests
Completed 100000 requests
Finished 100000 requests


Server Software:        waitress
Server Hostname:        127.0.0.1
Server Port:            8080

Document Path:          /create-gift-certificate
Document Length:        71408 bytes

Concurrency Level:      5
Time taken for tests:   3034.757 seconds
Complete requests:      100000
Failed requests:        0
Total transferred:      7164300000 bytes
Total body sent:        26900000
HTML transferred:       7140800000 bytes
Requests per second:    32.95 [#/sec] (mean)
Time per request:       151.738 [ms] (mean)
Time per request:       30.348 [ms] (mean, across all concurrent requests)
Transfer rate:          2305.42 [Kbytes/sec] received
                        8.66 kb/s sent
                        2314.08 kb/s total

Connection Times (ms)
              min  mean[+/-sd] median   max
Connect:        0    0   1.3      0      16
Processing:    85  151  27.2    149     784
Waiting:       85  150  27.0    147     784
Total:         85  152  27.2    149     784

Percentage of the requests served within a certain time (ms)
  50%    149
  66%    161
  75%    170
  80%    172
  90%    191
  95%    202
  98%    214
  99%    223
 100%    784 (longest request)

C:\\>
`;


export const callRecordings = `
C:\\>ab -n 100000 -c 50 http://127.0.0.1:8080/call-recordings
This is ApacheBench, Version 2.3 <$Revision: 1903618 $>
Copyright 1996 Adam Twiss, Zeus Technology Ltd, http://www.zeustech.net/
Licensed to The Apache Software Foundation, http://www.apache.org/

Benchmarking 127.0.0.1 (be patient)
Completed 10000 requests
Completed 20000 requests
Completed 30000 requests
Completed 40000 requests
Completed 50000 requests
Completed 60000 requests
Completed 70000 requests
Completed 80000 requests
Completed 90000 requests
Completed 100000 requests
Finished 100000 requests


Server Software:        waitress
Server Hostname:        127.0.0.1
Server Port:            8080

Document Path:          /call-recordings
Document Length:        1378 bytes

Concurrency Level:      50
Time taken for tests:   81.544 seconds
Complete requests:      100000
Failed requests:        0
Total transferred:      155700000 bytes
HTML transferred:       137800000 bytes
Requests per second:    1226.33 [#/sec] (mean)
Time per request:       40.772 [ms] (mean)
Time per request:       0.815 [ms] (mean, across all concurrent requests)
Transfer rate:          1864.65 [Kbytes/sec] received

Connection Times (ms)
              min  mean[+/-sd] median   max
Connect:        0    0   1.4      0      16
Processing:    16   41   7.3     38      69
Waiting:        0   39   7.1     38      69
Total:         16   41   7.3     38      69

Percentage of the requests served within a certain time (ms)
  50%     38
  66%     47
  75%     47
  80%     47
  90%     47
  95%     53
  98%     53
  99%     53
 100%     69 (longest request)

C:\\>`

export const getImage = `
C:\\>ab -n 100000 -c 50 http://127.0.0.1:8080/get-image
This is ApacheBench, Version 2.3 <$Revision: 1903618 $>
Copyright 1996 Adam Twiss, Zeus Technology Ltd, http://www.zeustech.net/
Licensed to The Apache Software Foundation, http://www.apache.org/

Benchmarking 127.0.0.1 (be patient)
Completed 10000 requests
Completed 20000 requests
Completed 30000 requests
Completed 40000 requests
Completed 50000 requests
Completed 60000 requests
Completed 70000 requests
Completed 80000 requests
Completed 90000 requests
Completed 100000 requests
Finished 100000 requests


Server Software:        waitress
Server Hostname:        127.0.0.1
Server Port:            8080

Document Path:          /get-image
Document Length:        69580 bytes

Concurrency Level:      50
Time taken for tests:   132.606 seconds
Complete requests:      100000
Failed requests:        0
Total transferred:      6983900000 bytes
HTML transferred:       6958000000 bytes
Requests per second:    754.11 [#/sec] (mean)
Time per request:       66.303 [ms] (mean)
Time per request:       1.326 [ms] (mean, across all concurrent requests)
Transfer rate:          51432.14 [Kbytes/sec] received

Connection Times (ms)
              min  mean[+/-sd] median   max
Connect:        0    0   1.1      0      16
Processing:    22   66  25.7     55     414
Waiting:        0   64  24.8     53     396
Total:         22   66  25.8     55     414

Percentage of the requests served within a certain time (ms)
  50%     55
  66%     63
  75%     69
  80%     77
  90%     91
  95%    119
  98%    155
  99%    170
 100%    414 (longest request)

C:\\>`

export const validatePassword = `
C:\\>ab -n 100000 -c 50 -p postData.json -T application/json http://127.0.0.1:8080/validate-password
This is ApacheBench, Version 2.3 <$Revision: 1903618 $>
Copyright 1996 Adam Twiss, Zeus Technology Ltd, http://www.zeustech.net/
Licensed to The Apache Software Foundation, http://www.apache.org/

Benchmarking 127.0.0.1 (be patient)
Completed 10000 requests
Completed 20000 requests
Completed 30000 requests
Completed 40000 requests
Completed 50000 requests
Completed 60000 requests
Completed 70000 requests
Completed 80000 requests
Completed 90000 requests
Completed 100000 requests
Finished 100000 requests


Server Software:        waitress
Server Hostname:        127.0.0.1
Server Port:            8080

Document Path:          /validate-password
Document Length:        94 bytes

Concurrency Level:      50
Time taken for tests:   85.813 seconds
Complete requests:      100000
Failed requests:        0
Total transferred:      27100000 bytes
Total body sent:        18900000
HTML transferred:       9400000 bytes
Requests per second:    1165.32 [#/sec] (mean)
Time per request:       42.907 [ms] (mean)
Time per request:       0.858 [ms] (mean, across all concurrent requests)
Transfer rate:          308.40 [Kbytes/sec] received
                        215.08 kb/s sent
                        523.48 kb/s total

Connection Times (ms)
              min  mean[+/-sd] median   max
Connect:        0    0   0.3      0       4
Processing:     9   43   4.3     42     118
Waiting:        2   42   4.2     41     104
Total:         10   43   4.4     42     118

Percentage of the requests served within a certain time (ms)
  50%     42
  66%     43
  75%     44
  80%     45
  90%     47
  95%     50
  98%     54
  99%     59
 100%    118 (longest request)

C:\\>`

export const sampleForms = `
C:\\>ab -n 100000 -c 50 -p postData.json -T application/json http://127.0.0.1:8080/sample-forms
This is ApacheBench, Version 2.3 <$Revision: 1903618 $>
Copyright 1996 Adam Twiss, Zeus Technology Ltd, http://www.zeustech.net/
Licensed to The Apache Software Foundation, http://www.apache.org/

Benchmarking 127.0.0.1 (be patient)
Completed 10000 requests
Completed 20000 requests
Completed 30000 requests
Completed 40000 requests
Completed 50000 requests
Completed 60000 requests
Completed 70000 requests
Completed 80000 requests
Completed 90000 requests
Completed 100000 requests
Finished 100000 requests


Server Software:        waitress
Server Hostname:        127.0.0.1
Server Port:            8080

Document Path:          /sample-forms
Document Length:        100 bytes

Concurrency Level:      50
Time taken for tests:   87.493 seconds
Complete requests:      100000
Failed requests:        0
Total transferred:      27800000 bytes
Total body sent:        18500000
HTML transferred:       10000000 bytes
Requests per second:    1142.95 [#/sec] (mean)
Time per request:       43.746 [ms] (mean)
Time per request:       0.875 [ms] (mean, across all concurrent requests)
Transfer rate:          310.29 [Kbytes/sec] received
                        206.49 kb/s sent
                        516.79 kb/s total

Connection Times (ms)
              min  mean[+/-sd] median   max
Connect:        0    0   1.5      0      16
Processing:     0   44   7.3     47      69
Waiting:        0   42   7.4     47      69
Total:          0   44   7.3     47      69

Percentage of the requests served within a certain time (ms)
  50%     47
  66%     47
  75%     47
  80%     47
  90%     53
  95%     53
  98%     53
  99%     53
 100%     69 (longest request)

C:\\>`