import React, { useState, useEffect, useRef } from "react";
import {
  Typography,
  Box,
  Grid,
  Button,
  ButtonGroup,
  TextField,
  Tabs,
  Tab,
  LinearProgress,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  Checkbox,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import HTTPResponse from "./HTTPResponse";
import { APIUrl } from "../settings";
import CodeSnippet from "./CodeSnippet";
import ReCAPTCHA from "react-google-recaptcha";
import * as settings from "../settings";
import Alert from "@mui/material/Alert";
import CheckIcon from "@mui/icons-material/Check";
import BenchmarkQuestion from "./BenchmarkQuestion";

export default function APICaller({
  setSiteName,
  setIsBreakingDown,
  method,
  endpoint,
  details,
  inputs = [],
  sourceCodeLanguage,
  sourceCodeSample,
  benchmarkTotalRequests,
  benchmarkSuccessPercentage,
  benchmarkResults
}) {
  const [inputValues, setInputValues] = useState(() =>
    inputs.reduce((acc, input) => {
      acc[input.label] =
        input.defaultValue !== undefined
          ? input.defaultValue
          : input.type === "checkbox"
          ? false
          : "";
      return acc;
    }, {})
  );

  const [activeTab, setActiveTab] = useState(0);
  const [responseVisible, setResponseVisible] = useState(false);
  const [apiResponse, setApiResponse] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [serverResponseTime, setServerResponseTime] = useState(null);
  const [recaptchaToken, setRecaptchaToken] = useState(null);

  const recaptchaRef = useRef(null);

  useEffect(() => {
    setResponseVisible(false);
  }, [endpoint]);

  useEffect(() => {
    setInputValues(
      inputs.reduce((acc, input) => {
        acc[input.label] =
          input.defaultValue !== undefined
            ? input.defaultValue
            : input.type === "checkbox"
            ? false
            : "";
        return acc;
      }, {})
    );
  }, [inputs]);

  const handleInputChange = (label, value) => {
    setInputValues({ ...inputValues, [label]: value });
  };

  const handleSendRequest = async () => {
    if (!recaptchaToken) {
      setError("Please complete the reCAPTCHA challenge.");
      setResponseVisible(true);
      return;
    }

    setIsLoading(true);
    const startTime = Date.now();

    try {
      let fullUrl = `${APIUrl}${endpoint}`;

      if (method === "GET" && inputs.length > 0) {
        const queryParams = new URLSearchParams();
        for (const [key, value] of Object.entries(inputValues)) {
          if (value !== "" && value !== undefined) {
            queryParams.append(key, value);
          }
        }
        if (queryParams.toString()) {
          fullUrl += `?${queryParams.toString()}`;
        }
      }

      const options = {
        method,
        headers: {
          "Content-Type": "application/json",
        },
        body:
          method !== "GET"
            ? JSON.stringify({ ...inputValues, recaptchaToken })
            : null,
      };

      const response = await fetch(fullUrl, options);
      const endTime = Date.now();
      setServerResponseTime(endTime - startTime);

      let responseBody = null;
      if (response.headers.get("Content-Type")?.includes("application/json")) {
        responseBody = await response.json();
      } else {
        responseBody = await response.text();
      }

      if (
        typeof responseBody === "object" &&
        responseBody !== null &&
        responseBody.actions
      ) {
        if (responseBody.actions.change_site_name) {
          setSiteName(responseBody.actions.change_site_name);
        }
        if (responseBody.actions.delete_website) {
          setIsBreakingDown(true);
        }
      }

      setApiResponse({
        status: {
          code: response.status,
          text: response.statusText,
        },
        headers: {
          "Content-Type": response.headers.get("Content-Type"),
          "Cache-Control": response.headers.get("Cache-Control"),
        },
        body:
          typeof responseBody === "string"
            ? responseBody
            : JSON.stringify(responseBody, null, 2),
      });
      setResponseVisible(true);
      setError(null);
    } catch (err) {
      setError(`Error: ${err.message}`);
      setApiResponse(null);
      setResponseVisible(true);
    } finally {
      setIsLoading(false);
    }
  };

  const onRecaptchaChange = (token) => {
    setRecaptchaToken(token);
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center" gap={2}>
      <ButtonGroup variant="contained" aria-label="API method buttons">
        <Button color="primary" disabled={method !== "GET"}>
          GET
        </Button>
        <Button color="success" disabled={method !== "POST"}>
          POST
        </Button>
        <Button color="warning" disabled={method !== "PATCH"}>
          PATCH
        </Button>
        <Button color="error" disabled={method !== "DELETE"}>
          DELETE
        </Button>
      </ButtonGroup>
      <div>{details}</div>
      {benchmarkSuccessPercentage && (
        <Alert severity="success" action={<BenchmarkQuestion benchmarkResults={benchmarkResults} />}>
          
          This API Endpoint scales and has a {benchmarkSuccessPercentage}% success rate
          when benchmarked at{" "}
          {Intl.NumberFormat("en-US").format(benchmarkTotalRequests)} requests.
          
        </Alert>


      )}

      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} md={6}>
          <TextField
            label="Endpoint"
            variant="outlined"
            fullWidth
            value={APIUrl + endpoint}
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>

        {inputs.length > 0 &&
          inputs.map((input) => {
            switch (input.type) {
              case "text":
                return (
                  <Grid item xs={12} key={input.label}>
                    <TextField
                      label={input.label}
                      variant="outlined"
                      fullWidth
                      value={inputValues[input.label] || ""}
                      onChange={(e) =>
                        handleInputChange(input.label, e.target.value)
                      }
                    />
                  </Grid>
                );
              case "radio":
                return (
                  <Grid item xs={12} key={input.label}>
                    <FormControl component="fieldset">
                      <Typography>{input.label}</Typography>
                      <RadioGroup
                        value={inputValues[input.label] || ""}
                        onChange={(e) =>
                          handleInputChange(input.label, e.target.value)
                        }
                      >
                        {input.options.map((option) => (
                          <FormControlLabel
                            key={option.value}
                            value={option.value}
                            control={<Radio />}
                            label={option.label}
                          />
                        ))}
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                );
              case "checkbox":
                return (
                  <Grid item xs={12} key={input.label}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={!!inputValues[input.label]}
                          onChange={(e) =>
                            handleInputChange(input.label, e.target.checked)
                          }
                        />
                      }
                      label={input.label}
                    />
                  </Grid>
                );
              case "dropdown":
                return (
                  <Grid item xs={12} key={input.label}>
                    <FormControl fullWidth>
                      <InputLabel>{input.label}</InputLabel>
                      <Select
                        value={inputValues[input.label] || ""}
                        onChange={(e) =>
                          handleInputChange(input.label, e.target.value)
                        }
                      >
                        {input.options.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                );
              default:
                return null;
            }
          })}

        <Grid item xs={12}>
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey={settings.RECAPTCHATOKEN}
            onChange={onRecaptchaChange}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <Button
            variant="contained"
            startIcon={<SendIcon />}
            fullWidth
            onClick={handleSendRequest}
          >
            Send Request
          </Button>
        </Grid>
      </Grid>

      {isLoading && (
        <Box width="100%">
          <LinearProgress />
        </Box>
      )}

      {responseVisible && (
        <>
          <Tabs
            value={activeTab}
            onChange={(e, newValue) => setActiveTab(newValue)}
            aria-label="response tabs"
          >
            <Tab label="Response Preview" />
            <Tab label="Endpoint Source Code" />
          </Tabs>
          {activeTab === 0 && (
            <Box p={2} width="100%">
              {apiResponse ? (
                <HTTPResponse
                  status={apiResponse.status}
                  headers={apiResponse.headers}
                  body={apiResponse.body}
                  serverResponseTime={serverResponseTime}
                />
              ) : (
                <Typography color="error">{error}</Typography>
              )}
            </Box>
          )}

          {activeTab === 1 && (
            <Box p={2} width="100%">
              <div style={{ margin: "15px" }}>
                This is the API data logic. Note that rate limiting, logging,
                and other security features are not shown.
              </div>

              <CodeSnippet
                language={sourceCodeLanguage}
                code={sourceCodeSample}
              />
            </Box>
          )}
        </>
      )}
    </Box>
  );
}
