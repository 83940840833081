import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  Box,
  Card,
  CardContent,
  CardMedia,
} from "@mui/material";
import ReactPlayer from "react-player";
import Modal from "@mui/material/Modal";
import * as settings from "../settings";

const Videos = () => {
  const [selectedVideo, setSelectedVideo] = useState(null);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const videoData = [
    {
      id: 2,
      title: "Android/iOS React Native Field Technician App",
      description: "App to manage field technicians. Jobs are created through a separate dispatch app and appear in this mobile technician app. Includes login/logout mechanisms, JWT requests, job tracking, technician tracking, inventory management, payment reports, rule acceptance, and more.",
      thumbnail: `${settings.BASEURL}/media/TechnicianAppThumbnail.png`,
      url: `${settings.BASEURL}/media/TechnicianApp.mp4`, 
    },
    {
      id: 1,
      title: "Remote B2B Cold Caller React Portal",
      description: "Web portal that enables remote B2B cold callers and outbound salesmen to make calls. Calls are placed through the system and performance is tracked through the portal. Supports paying callers based on leads generated, time spent on phone, and other conversion metrics. Separate campaigns support different call lists, compensation, goals, and scripts. Includes login/logout mechanism and JWT requests.",
      thumbnail: `${settings.BASEURL}/media/CallPortalThumbnail.png`,
      url: `${settings.BASEURL}/media/CallPortal.mp4`,
    },
    {
      id: 4,
      title: "Automated SMS Menu React Portal",
      description: "Users create interactive text mesage menus. This helps them to answer frequently asked questions. Menus can be retrieved by texting a code to a number, like texting 'info' to '(847) 505-0101'. When the menu is sent, several options are available. Includes login/logout mechanisms and JWT requests.",
      thumbnail: `${settings.BASEURL}/media/AgentRemarksThumbnail.png`, 
      url: `${settings.BASEURL}/media/AgentRemarks.mp4`,
    },
    {
      id: 3,
      title: "Gift Certificate React Portal",
      description: "Users generate gift certificates for their clients. By filling out their client information and a personalized message, users' clients will receive a gift certificate by text message. Includes login/logout mechanisms and JWT requests.",
      thumbnail: `${settings.BASEURL}/media/GiftCertificatePortalThumbnail.png`, 
      url: `${settings.BASEURL}/media/GiftCertificatePortal.mp4`,
    },
    {
      id: 5,
      title: "Lead Generation Portal For Service Providers In PHP/HTML/Bootstrap",
      description: "Service providers like plumbers, electricians, etc. register their company information. After being vetted and approved, providers begin to receive calls to their company. Calls are charged at a flat rate per call. Calls are guaranteed to be within the provider's service types, or the provider can request a lead credit. Complete with login/logout mechanism and credit card payments.",
      thumbnail: `${settings.BASEURL}/media/TrizazzThumbnail.png`, 
      url: `${settings.BASEURL}/media/Trizazz.mp4`,
    },
  ];

  const handleVideoClick = (videoUrl) => {
    setSelectedVideo(videoUrl);
  };

  const handleClose = () => {
    setSelectedVideo(null);
  };

  return (
    <Box sx={{ padding: 3 }}>
      <Typography variant="h4" gutterBottom>
        Videos
      </Typography>

      <Grid container spacing={4}>
        {videoData.map((video) => (
          <Grid item key={video.id} xs={12} sm={6} md={6}>
            <Card
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                height: "100%",
                cursor: "pointer",
              }}
              onClick={() => handleVideoClick(video.url)}
            >
              <CardMedia
                component="img"
                height="240"
                image={video.thumbnail}
                alt={video.title}
              />
              <CardContent>
                <Typography variant="h6">{video.title}</Typography>
                <Typography variant="body2" color="textSecondary" gutterBottom>
                  {video.description}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      {/* Modal for video player */}
      <Modal
        open={!!selectedVideo}
        onClose={handleClose}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "rgba(0, 0, 0, 0.8)",
        }}
      >
        <Box sx={{ position: "relative", width: "100%"}}>
          <ReactPlayer
            url={selectedVideo}
            controls
            width="100%"
            height="100%"
          />
        </Box>
      </Modal>
    </Box>
  );
};

export default Videos;
