import React, { useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import DebianCode from "./DebianCode";
import * as benchmarks from "../sourceCodeSamples/benchmarkResults";

// Styles for the modal
const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const BenchmarkQuestion = ({benchmarkResults}) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <Tooltip title="Benchmark Details" arrow>
        <IconButton
          sx={{ padding: "0px" }}
          color="primary"
          onClick={handleOpen}
        >
          <HelpOutlineIcon />
        </IconButton>
      </Tooltip>

      {/* Modal */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { xs: "90%", md: "80%" },
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 1,
            overflowY: "auto", // Enable scrolling
            maxHeight: "75%",
          }}
        >
          <Typography id="modal-title" variant="h6" component="h2">
            Benchmarking Details
          </Typography>
          <Typography id="modal-description" sx={{ mt: 2 }}>
            The endpoints were locally tested on a consumer-grade HP laptop with
            an i7 processor (Intel Core i7-8550U, 1.80GHz base speed, 2.00GHz
            max boost), 12GB of RAM, and 64-bit Windows 10.
          </Typography>

          <Typography id="modal-description" sx={{ mt: 2, mb: 3 }}>
            Benchmark tests (not included) conducted on Google Compute C4 Virtual Machines with
            SSD storage and Debian Linux showed performance improvements in speed and concurrency
            proportional to the enhanced hardware specifications
          </Typography>
          <DebianCode code={benchmarkResults} />
        </Box>
      </Modal>
    </>
  );
};

export default BenchmarkQuestion;
