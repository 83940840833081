import React from "react";
import { Typography, Box, Link as MuiLink } from "@mui/material";
import { Link } from "react-router-dom";

export default function Footer({ siteName }) {
  const currentYear = new Date().getFullYear(); // Get the current year

  return (
    <footer
      style={{
        marginTop: "auto",
        padding: "1em",
        textAlign: "center",
        background: "#f1f1f1",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          gap: "1.5em",
          mb: 2,
        }}
      >
        {/* Links */}
        <MuiLink component={Link} to="/api-explorer" color="inherit" underline="hover">
          API Explorer
        </MuiLink>
        <MuiLink component={Link} to="/projects" color="inherit" underline="hover">
          Projects
        </MuiLink>
        <MuiLink component={Link} to="/videos" color="inherit" underline="hover">
          Videos
        </MuiLink>
        <MuiLink component={Link} to="/about" color="inherit" underline="hover">
          About
        </MuiLink>
        <MuiLink component={Link} to="/contact" color="inherit" underline="hover">
          Contact
        </MuiLink>
      </Box>

      <Typography variant="body2" color="textSecondary">
        © {currentYear} {siteName}.com. All rights reserved.
      </Typography>
    </footer>
  );
}
