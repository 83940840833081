import React, { useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Modal,
  Box,
  Card,
  Grid,
} from "@mui/material";
import { Link } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";

import Lightbox from "react-awesome-lightbox";
import "react-awesome-lightbox/build/style.css"; // Include the Lightbox styles
import * as settings from "../settings";

const NavigationBar = ({ siteName }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [comVisible, setComVisible] = useState(false);
  const [industriesModalOpen, setIndustriesModalOpen] = useState(false);
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [lightboxImages, setLightboxImages] = useState([]);
  const [lightboxIndex, setLightboxIndex] = useState(0);

  useEffect(() => {
    const timer = setTimeout(() => {
      setComVisible(true);
    }, 500);
    return () => clearTimeout(timer);
  }, []);

  const toggleDrawer = (open) => (event) => {
    setDrawerOpen(open);
  };

  const handleImageClick = (images, index) => {
    setLightboxImages(images);
    setLightboxIndex(index);
    setLightboxOpen(true);
  };

  return (
    <>
      <AppBar position="static">
        <Toolbar>
          <Typography
            variant="h6"
            component={Link}
            to="/"
            style={{
              flexGrow: 1,
              textDecoration: "none",
              color: "inherit",
              display: "flex",
              alignItems: "center",
            }}
          >
            {siteName}
            <span
              style={{
                fontSize: "0.5em",
                verticalAlign: "top",
                marginLeft: "0.2em",
                opacity: comVisible ? 1 : 0,
                transform: comVisible ? "translateY(0)" : "translateY(-10px)",
                transition: "opacity 1s ease-in, transform 1s ease-in",
              }}
            >
              .com
            </span>
          </Typography>

          {/* Desktop Buttons */}
          <div className="desktop-nav">
            <Button color="inherit" component={Link} to="/api-explorer">
              <b>API Explorer</b>
            </Button>
            <Button color="inherit" component={Link} to="/projects">
              <b>Projects</b>
            </Button>
            <Button color="inherit" component={Link} to="/videos">
              <b>Videos</b>
            </Button>

            <Button
              onClick={() => setIndustriesModalOpen(true)}
              color="inherit"
            >
              <b>Industries</b>
            </Button>
            <Button color="inherit" component={Link} to="/about">
              <b>About Me</b>
            </Button>
            <Button color="inherit" component={Link} to="/contact">
              <b>Contact</b>
            </Button>
          </div>

          {/* Mobile Menu */}
          <div className="mobile-nav">
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={toggleDrawer(true)}
            >
              <MenuIcon />
            </IconButton>
            <Drawer
              anchor="right"
              open={drawerOpen}
              onClose={toggleDrawer(false)}
            >
              <List style={{ width: "250px", padding: "16px" }}>
                <ListItem
                  button
                  component={Link}
                  to="/api-explorer"
                  onClick={toggleDrawer(false)}
                >
                  <ListItemText primary="API Explorer" />
                </ListItem>
                <ListItem
                  button
                  component={Link}
                  to="/projects"
                  onClick={toggleDrawer(false)}
                >
                  <ListItemText primary="Projects" />
                </ListItem>
                <ListItem
                  button
                  component={Link}
                  to="/videos"
                  onClick={toggleDrawer(false)}
                >
                  <ListItemText primary="Videos" />
                </ListItem>

                <ListItem
                  button
                  component={Link}
                  onClick={() => setIndustriesModalOpen(true)}
                >
                  <ListItemText primary="Industries" />
                </ListItem>
                <ListItem
                  button
                  component={Link}
                  to="/about"
                  onClick={toggleDrawer(false)}
                >
                  <ListItemText primary="About Me" />
                </ListItem>
                <ListItem
                  button
                  component={Link}
                  to="/contact"
                  onClick={toggleDrawer(false)}
                >
                  <ListItemText primary="Contact" />
                </ListItem>
              </List>
            </Drawer>
          </div>
        </Toolbar>

        <style>
          {`
            .desktop-nav {
              display: none;
            }
            .mobile-nav {
              display: block;
            }

            @media (min-width: 600px) {
              .desktop-nav {
                display: flex;
                gap: 16px;
              }
              .mobile-nav {
                display: none;
              }
            }

            .MuiDrawer-paper {
              width: 250px;
              background-color: #f4f4f4; 
              color: #333;
            }

            a {
              text-decoration: none;
              color: inherit;
            }

            a:visited {
              color: inherit;
            }

            .MuiListItem-root {
              padding: 12px 16px;
            }
          `}
        </style>
      </AppBar>

      <Modal
        open={industriesModalOpen}
        onClose={() => setIndustriesModalOpen(false)}
        aria-labelledby="industries-modal-title"
        aria-describedby="industries-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { xs: "90%", md: "80%" },
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 1,
            overflowY: "auto", // Enable scrolling
            maxHeight: "75%",
          }}
        >
          <Typography variant="h4" style={{ margin: "5px" }} gutterBottom>
            Industry Use Cases
          </Typography>
          <Typography
            id="industries-modal-description"
            style={{ margin: "5px", marginTop: "15px" }}
          >
            The software I develop is <b>completely custom</b> and has a variety
            of use cases across multiple industries.
          </Typography>
          <Typography
            id="industries-modal-description"
            style={{ margin: "5px", marginTop: "15px" }}
          >
            Whether you are technical or non-technicial, we can work together
            toward your technical goals. I can improve projects, creating additional
            features and functionality, or we can take an idea from 0-1, using
            your domain expertise to guide the development process.
          </Typography>
          <Typography
            id="industries-modal-description"
            style={{ margin: "5px", marginTop: "15px" }}
          >
            Together we can create{" "}
            <b>
              end to end, custom, high quality business and consumer
              applications
            </b> using the <b>latest technologies.</b>
            .
          </Typography>
          {/* Grid with Cards */}
          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={12} sm={6} md={4}>
              <Card variant="outlined" sx={{ p: 2 }}>
                <Typography variant="h6" gutterBottom>
                  MVP Creation
                </Typography>
                <Typography variant="body2">
                  We can create a MVP from 0, or I can pick up where you left
                  off. Regardless of the stage you're in, together we can work
                  toward your goals and create a MVP to test your idea in the
                  market. Rapid prototyping and ongoing support is available.
                </Typography>

                {/* Images with related Lightbox */}
                {[
                  `${settings.BASEURL}/sampleSiteScreenshots/mvp.png`,
                ].map((image, index) => (
                  <img
                    key={index}
                    src={image}
                    alt={`Restaurant ${index + 1}`}
                    style={{
                      width: "100%",
                      cursor: "pointer",
                      marginTop: "10px",
                    }}
                    onClick={() =>
                      handleImageClick(
                        [
                          `${settings.BASEURL}/sampleSiteScreenshots/mvp.png`,
                        ],
                        index
                      )
                    }
                  />
                ))}
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Card variant="outlined" sx={{ p: 2 }}>
                <Typography variant="h6" gutterBottom>
                  Real Estate
                </Typography>
                <Typography variant="body2">
                  We can create custom workflows to automate client outreach,
                  lead generation and inbound inquiries, systems to manage
                  listings, properties, tenants, and vendors, and easily
                  insertable web snippets for virtual tours, document signing,
                  and more.
                </Typography>

                {/* Images with related Lightbox */}
                {[
                  `${settings.BASEURL}/sampleSiteScreenshots/realEstateMain.png`,
                ].map((image, index) => (
                  <img
                    key={index}
                    src={image}
                    alt={`Restaurant ${index + 1}`}
                    style={{
                      width: "100%",
                      cursor: "pointer",
                      marginTop: "10px",
                    }}
                    onClick={() =>
                      handleImageClick(
                        [
                          `${settings.BASEURL}/sampleSiteScreenshots/realEstateMain.png`,
                        ],
                        index
                      )
                    }
                  />
                ))}
              </Card>
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <Card variant="outlined" sx={{ p: 2 }}>
                <Typography variant="h6" gutterBottom>
                  Communications
                </Typography>
                <Typography variant="body2">
                  We can create communication systems for phone, text, email,
                  fax, and will soon feature RCS support. Systems can be used
                  either standalone or can be integrated into other existing
                  systems. We can also replace your existing infastracture with
                  different providers. Examples include automated inbound phone
                  systems, email conversations, text message notifications and
                  communications, and more.
                </Typography>

                {/* Images with related Lightbox */}
                {[
                  `${settings.BASEURL}/sampleSiteScreenshots/telecommunications.png`,
                ].map((image, index) => (
                  <img
                    key={index}
                    src={image}
                    alt={`Restaurant ${index + 1}`}
                    style={{
                      width: "100%",
                      cursor: "pointer",
                      marginTop: "10px",
                    }}
                    onClick={() =>
                      handleImageClick(
                        [
                          `${settings.BASEURL}/sampleSiteScreenshots/telecommunications.png`,
                        ],
                        index
                      )
                    }
                  />
                ))}
              </Card>
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <Card variant="outlined" sx={{ p: 2 }}>
                <Typography variant="h6" gutterBottom>
                  Legal
                </Typography>
                <Typography variant="body2">
                  We can create tools for case management, including automated
                  case updates and client notifications. We can use AI to create
                  and send document summaries with adjustable levels of
                  verbosity and specific areas of interest, if desired.
                  Additionally, we can develop dedicated websites or embeddable
                  document signing widgets, which can be securely sent via text
                  message. Our solutions comply with U.S. legal standards,
                  ensuring data is encrypted, securely stored, and backed up,
                  with full ownership retained by the attorney. We also provide
                  audit trails and access controls to meet the confidentiality
                  and ethical obligations of legal professionals.
                </Typography>

                {/* Images with related Lightbox */}
                {[
                  `${settings.BASEURL}/sampleSiteScreenshots/legal.png`,
                ].map((image, index) => (
                  <img
                    key={index}
                    src={image}
                    alt={`Restaurant ${index + 1}`}
                    style={{
                      width: "100%",
                      cursor: "pointer",
                      marginTop: "10px",
                    }}
                    onClick={() =>
                      handleImageClick(
                        [
                          `${settings.BASEURL}/sampleSiteScreenshots/legal.png`,
                        ],
                        index
                      )
                    }
                  />
                ))}
              </Card>
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <Card variant="outlined" sx={{ p: 2 }}>
                <Typography variant="h6" gutterBottom>
                  Restaurant & Food Service
                </Typography>
                <Typography variant="body2">
                  We can create online menus, placing orders along with payment
                  processing, implementing rewards programs, and order stage
                  tracking. Provide customers realtime delivery times based on
                  driver location and automatically send review funnels by text
                  message or email. We can also create inventory tracking
                  systems and in-store ordering solutions for use by waitstaff.
                </Typography>

                {/* Images with related Lightbox */}
                {[
                  `${settings.BASEURL}/sampleSiteScreenshots/restaurantMain.png`,
                ].map((image, index) => (
                  <img
                    key={index}
                    src={image}
                    alt={`Restaurant ${index + 1}`}
                    style={{
                      width: "100%",
                      cursor: "pointer",
                      marginTop: "10px",
                    }}
                    onClick={() =>
                      handleImageClick(
                        [
                          `${settings.BASEURL}/sampleSiteScreenshots/restaurantMain.png`,
                        ],
                        index
                      )
                    }
                  />
                ))}
              </Card>
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <Card variant="outlined" sx={{ p: 2 }}>
                <Typography variant="h6" gutterBottom>
                  Education
                </Typography>
                <Typography variant="body2">
                  We can make solutions for educators like custom course
                  creation, remote testing for students, student entrollment and
                  onboarding systems, and more. We can create interactive
                  webinars and systems to manage faculty operations.
                  Multi-channel student notifications can be sent for school
                  events and fundraising efforts.
                </Typography>

                {/* Images with related Lightbox */}
                {[
                  `${settings.BASEURL}/sampleSiteScreenshots/education.png`,
                ].map((image, index) => (
                  <img
                    key={index}
                    src={image}
                    alt={`Restaurant ${index + 1}`}
                    style={{
                      width: "100%",
                      cursor: "pointer",
                      marginTop: "10px",
                    }}
                    onClick={() =>
                      handleImageClick(
                        [
                          `${settings.BASEURL}/sampleSiteScreenshots/education.png`,
                        ],
                        index
                      )
                    }
                  />
                ))}
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Card variant="outlined" sx={{ p: 2 }}>
                <Typography variant="h6" gutterBottom>
                  And More!
                </Typography>
                <Typography variant="body2">
                  Retail, Ecommerce, Transportation, Logistics, Travel and
                  Hospitality are just some of the industries serviced. If I
                  can't fulfill your needs, I may still be able to offer some
                  insights or suggestions for where to look next. Contact me with your unique
                  needs and I'll be glad to review them with you!
                </Typography>

                {/* Images with related Lightbox */}
                {[
                  `${settings.BASEURL}/sampleSiteScreenshots/custom.png`,
                ].map((image, index) => (
                  <img
                    key={index}
                    src={image}
                    alt={`Restaurant ${index + 1}`}
                    style={{
                      width: "100%",
                      cursor: "pointer",
                      marginTop: "10px",
                    }}
                    onClick={() =>
                      handleImageClick(
                        [
                          `${settings.BASEURL}/sampleSiteScreenshots/custom.png`,
                        ],
                        index
                      )
                    }
                  />
                ))}
              </Card>
            </Grid>
          </Grid>{" "}
        </Box>
      </Modal>

      {lightboxOpen && (
        <Lightbox
          images={lightboxImages.map((img) => ({ url: img }))}
          startIndex={lightboxIndex}
          onClose={() => setLightboxOpen(false)}
        />
      )}
    </>
  );
};

export default NavigationBar;
