import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Container } from "@mui/material";

import LandingPage from "./pages/LandingPage";
import ProjectsPage from "./pages/ProjectsPage";
import ProjectDetailsPage from "./pages/ProjectDetailsPage";
import AboutMePage from "./pages/AboutMePage";
import ResumePage from "./pages/ResumePage";
import ContactPage from "./pages/ContactPage";
import NavigationBar from "./components/NavigationBar";
import Footer from "./components/Footer";
import APIExplorer from "./pages/APIExplorer";
import Videos from "./pages/Videos";
import FeaturesPage from "./pages/FeaturesPage";
import Breakdown from "./components/Breakdown";
import FrequentlyAsked from "./pages/FrequentlyAsked";
import CookieNotification from "./components/CookieNotification";
import { Helmet } from "react-helmet";

const App = () => {
  const [siteName, setSiteName] = useState("MatthewKontos");
  const [isBreakingDown, setIsBreakingDown] = useState(false);
  const [name, setName] = useState("Matthew Kontos Developer");

  return (
    <Router>
      <div>
        {isBreakingDown && <Breakdown setIsBreaking={setIsBreakingDown} />}

        <NavigationBar siteName={siteName} />
        <Container maxWidth="lg" style={{ marginTop: "20px" }}>
          <Routes>
            <Route
              path="/"
              element={
                <>
                  <Helmet>
                    <title>Welcome | Home | {name}</title>
                    <meta
                      name="description"
                      content="Matthew Kontos: Developer, starter, partner, maker. "
                    />
                  </Helmet>
                  <LandingPage />
                </>
              }
            />
            <Route
              path="/projects"
              element={
                <>
                  <Helmet>
                    <title>Projects List | {name}</title>
                    <meta
                      name="description"
                      content="A list of my End to End created projects."
                    />
                  </Helmet>
                  <ProjectsPage />
                </>
              }
            />
            <Route
              path="/projects/:id"
              element={
                <>
                  <Helmet>
                    <title>Matthew Kontos Project | {name}</title>
                    <meta
                      name="description"
                      content=""
                    />
                  </Helmet>
                  <ProjectDetailsPage />
                </>
              }
            />
            <Route
              path="/about"
              element={
                <>
                  <Helmet>
                    <title>About | {name}</title>
                    <meta
                      name="description"
                      content="Matthew Kontos: Developer, starter, partner, maker."
                    />
                  </Helmet>
                  <AboutMePage />
                </>
              }
            />
            <Route
              path="/api-explorer"
              element={
                <>
                  <Helmet>
                    <title>API Explorer | {name}</title>
                    <meta
                      name="description"
                      content="A custom API Explorer to explore various API enpoints."
                    />
                  </Helmet>
                  <APIExplorer
                    setSiteName={setSiteName}
                    setIsBreakingDown={setIsBreakingDown}
                  />
                </>
              }
            />
            <Route
              path="/videos"
              element={
                <>
                  <Helmet>
                    <title>Project Videos | {name}</title>
                    <meta
                      name="description"
                      content="Videos of some of my projects."
                    />
                  </Helmet>
                  <Videos />
                </>
              }
            />
            <Route
              path="/contact"
              element={
                <>
                  <Helmet>
                    <title>Contact Developer | {name}</title>
                    <meta
                      name="description"
                      content="Contact me with questions and opportunities."
                    />
                  </Helmet>
                  <ContactPage />
                </>
              }
            />
          </Routes>
        </Container>
        <Footer siteName={siteName} />
        <CookieNotification />
      </div>
    </Router>
  );
};

export default App;
