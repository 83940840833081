import React, { useState, useEffect, useRef } from "react";
import { Box, Typography, Container, Fab } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close"; 
import { APIUrl } from "../settings";

const Breakdown = ({ setIsBreaking }) => {
  const [isBreakingDown, setIsBreakingDown] = useState(false);
  const [countdown, setCountdown] = useState(10);
  const [displayedText, setDisplayedText] = useState("");
  const [messageIndex, setMessageIndex] = useState(0);
  const [charIndex, setCharIndex] = useState(0);
  const [apiResponse, setApiResponse] = useState(null);
  const [messages, setMessages] = useState([]);
  const messagesEndRef = useRef(null);

  // Function to get the user agent
  const getUserAgent = () => {
    return navigator.userAgent;
  };

  const now = new Date();
  const time9 = new Date(now.getTime() - 9 * 60 * 1000);
  const options = { year: "numeric", month: "long", day: "numeric", hour: "numeric", minute: "numeric", hour12: true };
  const time9MinAgo = time9.toLocaleString("en-US", options);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${APIUrl}/check-ip-whois`);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setApiResponse(data);
        const attackerInfo = `
<system>Attacker Information:</system>\n
<system>ISP: ${data.isp}</system>\n
<system>Region Name: ${data.regionName}</system>\n
<system>Country Code: ${data.countryCode}</system>\n
<system>User Agent: ${getUserAgent()}</system>\n
<system>Latitude: ${data.lat}</system>\n
<system>Longitude: ${data.lon}</system>\n
<system>Organization: ${data.org}</system>\n
<system>Proxy: ${data.proxy}</system>\n
<system>Timezone: ${data.timezone}</system>\n
<user>...</user>\n
<system>Incident report created.</system>\n
<system>Securing system.</system>\n
<system>System logging off.</system>\n
`;
        const initialSimulationCommands = `
<system>root login initiated...</system>\n
<system>Welcome, root.</system>\n
<root># rm -rf /var/www/matthewkontos.com</root>\n
<system>Directory /var/www/matthewkontos.com deleted.</system>\n\n
<root># mysql</root>\n
<user>mysql> SHOW DATABASES;</user>\n
+--------------------+\n
| Database           |\n
+--------------------+\n
| information_schema |\n
| matthewkontos.com  |\n
| mysql              |\n
| performance_schema |\n
| sys                |\n
+--------------------+\n
5 rows in set (0.00 sec)\n\n
<user>mysql> USE \`matthewkontos.com\`;</user>\n
Database changed\n\n
<user>mysql> DROP TABLE projects, blog_posts, testimonials, contact_messages;</user>\n
Query OK, 0 rows affected (0.10 sec)\n\n
<user>mysql> DROP DATABASE \`matthewkontos.com\`;</user>\n
Query OK, 1 row affected (0.15 sec)\n\n
<user>mysql> SHOW DATABASES;</user>\n
+--------------------+\n
| Database           |\n
+--------------------+\n
| information_schema |\n
| mysql              |\n
| performance_schema |\n
| sys                |\n
+--------------------+\n
4 rows in set (0.00 sec)\n\n
<root># apt-get purge --auto-remove apache2 mysql-server -y</root>\n
Reading package lists... Done\n
Building dependency tree\n
Reading state information... Done\n
The following packages will be REMOVED:\n
apache2* mysql-server*\n
0 upgraded, 0 newly installed, 2 to remove and 0 not upgraded.\n
After this operation, 457MB of disk space will be freed.\n
(Reading database ... Done)\n
Removing apache2 (2.4.62)...\n
Removing mysql-server (8.0.36)...\n
Purging configuration files for apache2 and mysql-server...\n
<root># sudo rm -rf /</root>\n
<system>Warning: This action will delete all data on this system.</system>\n
<system>Proceeding with system-wide deletion...</system>\n
<system>Deleting...</system>\n
<system>System error: Critical processes terminated. System crash imminent.</system>\n
<user>...</user>\n
<user>......</user>\n
<user>............</user>\n
<user>...............</user>\n
<system>System Automatic Backup Restoring to last backup taken 9 minutes ago at: ${time9MinAgo}</system>\n
<user>...</user>\n
<user>......</user>\n
<user>............</user>\n
<system>Server matthewkontos.com has been fully restored to the last backup created ${time9MinAgo}.</system>\n
<system>Getting Attacker Information.</system>\n
<user>...</user>\n
<user>......</user>\n
<user>............</user>\n
`;
        setMessages(initialSimulationCommands.split("\n").concat(attackerInfo.split("\n")));
      } catch (error) {
        console.error("Failed to fetch data:", error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const startBreakdown = () => {
      setIsBreakingDown(true);
    };
    startBreakdown();
    if (isBreakingDown) {
      const interval = setInterval(() => {
        setCountdown((prevCount) => (prevCount > 0 ? prevCount - 1 : 0));
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [isBreakingDown]);

  useEffect(() => {
    if (messageIndex < messages.length) {
      const currentMessage = messages[messageIndex];
      if (
        currentMessage.startsWith("<root>") ||
        currentMessage.startsWith("<user>") ||
        currentMessage.startsWith("<system>")
      ) {
        const strippedMessage = currentMessage.replace(/<[^>]*>/g, "");
        if (charIndex < strippedMessage.length) {
          const typingTimeout = setTimeout(() => {
            setDisplayedText((prev) => prev + strippedMessage[charIndex]);
            setCharIndex((prev) => prev + 1);
          }, 50);
          return () => clearTimeout(typingTimeout);
        } else {
          setCharIndex(0);
          setMessageIndex((prev) => prev + 1);
          setDisplayedText((prev) => prev + "\n");
        }
      } else {
        setDisplayedText((prev) => prev + currentMessage + "\n");
        setMessageIndex((prev) => prev + 1);
      }
    }
  }, [messageIndex, charIndex, messages]);

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [displayedText]);

  const styles = {
    container: {
      width: "100vw",
      height: "100vh",
      position: "fixed",
      top: 0,
      left: 0,
      zIndex: 9999,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      backgroundColor: "black",
      color: "lime",
      fontFamily: `'Press Start 2P', monospace`,
      fontSize: "1rem",
      textAlign: "left",
      whiteSpace: "pre-wrap",
      overflowY: "auto",
      pointerEvents: "auto",
    },
    content: {
      maxHeight: "90vh",
      overflowY: "auto",
      width: "100%",
    },
  };

  return (
    <div style={styles.container}>
      <style>
        {`
          @keyframes pulseRed {
            0%, 100% { background-color: rgba(255, 0, 0, 0); }
            50% { background-color: rgba(255, 0, 0, 0.5); }
          }
          @import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');
        `}
      </style>
      <Container style={styles.content}>
        <Typography
          component="pre"
          style={{
            color: "lime",
            whiteSpace: "pre-wrap",
            wordWrap: "break-word",
            overflowWrap: "break-word",
          }}
        >
          {displayedText}
        </Typography>
        <div ref={messagesEndRef}></div>
      </Container>

        <Fab
          color="error"
          aria-label="close"
          style={{ position: "fixed", bottom: 16, right: 16 }}
          onClick={() => setIsBreaking(false)}
        >
          <CloseIcon />
        </Fab>

    </div>
  );
};

export default Breakdown;
