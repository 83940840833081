import React from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { prism } from 'react-syntax-highlighter/dist/esm/styles/prism'; // Import a Prism theme, change this as per your preferred theme

const HTTPResponse = ({ status, headers, body, serverResponseTime }) => {
  // Determine if the body should be displayed as an image based on Content-Type header
  const contentType = headers['Content-Type'] || headers['content-type'];
  const isImage = contentType && contentType.startsWith('image/');

  // Filter out 'Cache-Control' from the headers
  const filteredHeaders = Object.entries(headers).filter(([key]) => key.toLowerCase() !== 'cache-control');

  return (
    <div style={{ border: '1px solid #ccc', borderRadius: '4px', overflow: 'hidden', fontFamily: 'monospace', backgroundColor: '#f9f9f9' }}>
      {/* Status */}
      <div style={{ 
        backgroundColor: '#2d2d2d', 
        color: '#ffffff', 
        padding: '8px', 
        fontSize: '0.9rem', 
        fontWeight: 'bold' 
      }}>
        HTTP/1.1 {status.code} {status.text}
      </div>
      
      {/* Server Response Time */}
      {serverResponseTime && (
        <div style={{ 
          padding: '8px', 
          borderBottom: '1px solid #ccc', 
          backgroundColor: '#f3f3f3', 
          fontSize: '0.9rem', 
          fontStyle: 'italic' 
        }}>
          Response Time: {serverResponseTime} ms
        </div>
      )}
      
      {/* Headers */}
      <div style={{ padding: '8px', borderBottom: '1px solid #ccc', backgroundColor: '#f1f1f1', fontSize: '0.9rem' }}>
        {filteredHeaders.map(([key, value]) => (
          <div key={key}>
            <strong>{key}:</strong> {value}
          </div>
        ))}
      </div>
      
      {/* Body */}
      {isImage ? (
        <div style={{ padding: '8px', textAlign: 'center' }}>
          <img src={`data:${contentType};base64,${body}`} alt="Response Content" style={{ maxWidth: '100%', maxHeight: '400px' }} />
        </div>
      ) : (
        <SyntaxHighlighter
          language="json" // Assuming the body is typically JSON, change it as needed
          style={prism} // Applying Prism theme
          showLineNumbers
          wrapLongLines
        >
          {body}
        </SyntaxHighlighter>
      )}
    </div>
  );
};

export default HTTPResponse;
