export const APIUrl = "https://matthewkontos.com/api";
export const BASEURL = "https://matthewkontos.com";

export const RECAPTCHATOKEN = "6LeRu48qAAAAAFq5V6It2M-TgLZsQ9n1rda38m1Z";
export const RECAPTCHAV3TOKEN = "6Le0u48qAAAAALwoQL7WvsX0faChrTQFaPfF5cvA";



export const PGPKEY = `-----BEGIN PGP PUBLIC KEY BLOCK-----

mQINBGdWCK0BEADaVr25+2OrUS8vrYxb7cbHRQ04Qu/7Ol4FGJ6rqRF4focQGm67
xODtLqapsdt0kQYUUTOd8devG1DHYtPaMvtVeHnmVXqveeHHGt6cvlP4XjF05Tkr
377j8ebCD2k7ZRbKbpA1qHA1tt95PpWDBThQZY0HqnAhpg4c/MaOjiKo9hKEuVIj
/FkPvwRKhxmVbsm/jx7jkejxWoaRFa41dEuGqg3jv4cfVdDYvQoFDmIhJAwS0CSB
NwEQRmjiUA+RzgOost7V1GAkrLmcgYqryCKnzbnS5iDqzfLtjBoTxcOykRfCTqEu
ILa9r2Q8tIudzrF6jWpf0Lc20nc8IRNEkk3FZf3HJDdTyFRKB/UO9lsThqh7BLmz
XWEsZWZQ+S4pfBETVZcFgfGpns2+qZ6sh45NirU5cjc7lZeqgeiIudZ+EmkWOWnr
h5IJEUJZq0mrqzY/wyTnQejvMZGbWj9m8CxUBWAIFfhsan2aaCXhnv2n2FvorJna
mZPDkPnx2FhCny1ES+Pk7lFuHhGv31LbJ0NII3zCcsw01GicXN4OcuBlO20WNTLH
KgPjOIFO2ik6kh/65PPlwuO4WsI1Rhys12mFSCj22Cv7/Iev8vWuF7hArMKTkeuH
utXKYBSqPiHySvD9VoSFhbV46bWhP1FeZdWox+BSeUdWu3tuhfk5qhpZhwARAQAB
tChNYXR0aGV3IEtvbnRvcyA8bWF0dGhld2tvbnRvc0BnbWFpbC5jb20+iQJOBBMB
CgA4FiEERLtJ9Qo5uKzXzk2BDz1kwCLMDxIFAmdWCK0CGwMFCwkIBwIGFQoJCAsC
BBYCAwECHgECF4AACgkQDz1kwCLMDxK2vQ//WBw20mEdSKqfDJj1qAJEWHaCJUnY
UglEjVHN3gmqFN0zHZw6c9p0claNL9ynOORHCNYlYt+BYVfTuT1aVFwpxFtTdqiX
/qSpls5wrmXJyBwdtRj8o+FwpNn2+hqXySGJtjkD753lbr3cB5QGjx95lrgcdkwz
pbw+6TIGvW0iSnHdinpTbMPGp4JpLmv/l8d0P4Jjluf4EL1vsxdPDZtFIxQf62ax
+UBt7Of/b8nlx6bhD6PAzbf3x6Mhu5ZN49i2nxh8Dyoe2daVtLyV9s/RGGjIa41p
nooDnsAoYB1Bp+qvBFLda2/L8NJjurgbyLQyWknXbANUPkofvG8rq4UmAtCRCzUc
JG2kzbo/WLVZ30eL5KgDsLiIU/yseC/lfnkJh1SbMObw0Sx/oNCDRp+5lBEn32tY
GXhYaHzh7DYkdvbHZ14MkPkttbHDcV1ETa/hyQqC8qYKFypzzFQt5K8dMUALAPYz
DHa9ilR2DiSZb34CRjsifGYF/0T0DliKyguOuWl5K1HTmmEt0lwZ/7fvxKfF780T
o/Y+w//8MQlfuvj4CpPXX3DjHswD9azjW3CB1Pu4ZyEZg48SqqVN/5pFUOSLoYVN
i6NHxJ3DnEVCuopfJiN4fSFonm+9pjwdpTwNBHg39epzxPqmE8D1bDnceIxzL3Qm
ly0BnyrjulDlhRy5Ag0EZ1YIrQEQANUxSDb92/+RBALiw/YcrbjKqWF2jEQthezO
LZDdd5B0iqN+T6uGsy1eBCvFgnBbKdWPQpb4MBJYKnMLZAhpg+zMKR3KrsT5ZGKm
Zf0k3xj9fzHoMS3WVI7OaOL4bVDifZSdcyUq6/dPxiTu+kHzA7o4hJExBPcizgrc
6m+hV0W6jhBbe7v1kSmDn5TJGBF5B8G/1gi/P7xZSbyY8/Tgv0PQdJIo5+J9CxxT
40ROIf76Pg/TqLgYYyU0218IlKDqP88OuYqJGunilwf+2Ccaw2PEJC1Y06XJBQrH
0d1mTmPzJbpcJzBjYXvrghdmsfaLgc6fYIM4T++cdKIJElveYjqkUSQVt7WlQDd1
HCLj3HaPVSNjhcAnm9vJ2edGlvNChowJw9opstvzOrO/z+Y7BuBhtGqYfCPCb7Vf
OuATkfYyJiTi1A59qijSAnK2HxGvILdRQR6jaUqHUWnw9nXEyKgtXg9BYjUx+WDm
l9s/ElqFnempJS4fJxtDGd9Yrs+bsxygaT7rn8kvVBIeN8wO3LofDxFbJlkBmF55
9vpgtWMgqUdmbXI6Ub3ZSAZdYcxntK/ty7PmlYHzg2IKUuBIChYZy9zU/Pqomt6R
ouIszYKlAr22oTAzqjjS8wySyLpAxdGYtXdzSPIomc95tN6BvSYkM+KoDxbdGX7H
4Pso6VRFABEBAAGJAjYEGAEKACAWIQREu0n1Cjm4rNfOTYEPPWTAIswPEgUCZ1YI
rQIbDAAKCRAPPWTAIswPEq4yD/4ykRIL/BycFrtOnRcwmOvqPJRdP66S/KqrWflr
DUbx3kX71tcUEmU4spxwyimTk4vAUB6FeXwIssn1TB10d9XqZoq3hBBdObrHJ+9I
K0mbOiJMVOT+jHlUlYP7bMWuaYhFMefzOR0RVQ22Qxr0IyqO0v7V7k8kX5SDtsh8
FsvSv2YCouI1Q1vuJfoDfXz49l6mEKmJF4XWtNENQy8bTS2mOlboqhmU6Br7qVIJ
OUhgz0QfyqGX463jEP7RPguTPR+5FHHrouPMR6DBONo+c95Xsv66ZG7siC+KeCKv
NKDeS1iKiP0iqgGoYfCv5xafkGmoffw6MD+ezNb4PQR+2yAo0iLvpyUATjCdSrvb
vVSNLJWVzrkFkqeLvUtVRaIxa2XC35DXSowEPEreXoiFzJWAwaGIXV/n0iFv6v44
DRXjmENkiux8P2tPeQnhtXsu8M6vI1b2S+SzKw6gtvBV+at4o5veusOkrW+OQdkv
UawRGPE3gyAZZdrDu+b334llaC0Wo+7C5yRM0h4diIdQznH3KZVSOOIq8KrW/PMN
pRMDQE0Rm7Iafr+2aY3K6zjEUBYLKbHR6Uzw1uN/gZdE94ZR25yIc4AUfXCnqPlp
atw8EpAXh7wP7nw3ge+nr2qZ14iojuFH8rAxhbNyyAPbAECqe80YbHEU20rLvdJu
QKGwGw==
=t+YT
-----END PGP PUBLIC KEY BLOCK-----`