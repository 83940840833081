import React, { useState } from "react";
import {
  Typography,
  Box,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Chip,
  Modal,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import APICaller from "../components/APICaller";
import {

  createGiftCertificateSample,
} from "../sourceCodeSamples/sourceCodeSamples";
import APIExplorerComponents from "../components/APIExplorerComponents";
import {accordionData} from "../sourceCodeSamples/accordionData";
import * as benchmarks from "../sourceCodeSamples/benchmarkResults";


const methodColors = {
  GET: "#2196f3",
  POST: "#4caf50",
  PATCH: "#ff9800",
  DELETE: "#f44336",
};

export default function APIExplorer({ setSiteName, setIsBreakingDown }) {
  const [selectedAPI, setSelectedAPI] = useState({
    method: "POST",
    endpoint: "/create-gift-certificate",
    details:
      "Creates a sample gift certificate. A template image is loaded and text is superimposed onto the image.",
    inputs: [
      {
        type: "text",
        label: "recipient_name",
        defaultValue: "Satisfied Customer",
      },
      {
        type: "text",
        label: "sender_name",
        defaultValue: "Qualified Sender",
      },
      {
        type: "text",
        label: "amount",
        defaultValue: "50.00",
      },
    ],
    sourceCodeLanguage: "python",
    sourceCodeSample: createGiftCertificateSample,
    benchmarkTotalRequests: 100000,
    benchmarkSuccessPercentage: 100,
    benchmarkResults: benchmarks.giftCertificate
  });

  const [openModal, setOpenModal] = useState(false);

  return (
    <Box sx={{ padding: 3 }}>
      <Typography variant="h4" gutterBottom>
        API Explorer
      </Typography>
      <Typography variant="body1" paragraph>
        API Calls are used to communicate with the server and interact with
        data.
      </Typography>
      <Typography variant="body1" paragraph>
        This sample of simple demonstration API calls, as well as the API Explorer, was created to showcase custom, reactive, and secure coding practices. 
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={12} md={3}>
          <Box sx={{ border: "1px solid #ccc", padding: 2 }}>
            <Typography variant="h6" style={{ marginBottom: "15px" }}>
              Request Methods
            </Typography>
            {accordionData.map((item, index) => (
              <Accordion key={index}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`panel${index}-content`}
                  id={`panel${index}-header`}
                >
                  <Grid container alignItems="center" spacing={1}>
                    <Grid item>
                      <Chip
                        label={item.title}
                        sx={{
                          backgroundColor: methodColors[item.title] || "#000",
                          color: "white",
                          fontWeight: "bold",
                        }}
                      />
                    </Grid>
                    <Grid item xs>
                      <Typography>{item.description}</Typography>
                    </Grid>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails>
                  {item.content.map((contentItem, contentIndex) => (
                    <Box
                      key={contentIndex}
                      sx={{
                        padding: 1,
                        "&:hover": {
                          backgroundColor: "#e0e0e0",
                          cursor: "pointer",
                        },
                        transition: "background-color 0.3s ease",
                        marginBottom: 1,
                      }}
                      onClick={() => {
                        setSelectedAPI({
                          method: item.title,
                          endpoint: contentItem.endpoint,
                          details: contentItem.details || "",
                          inputs: contentItem.inputs || [],
                          sourceCodeSample: contentItem.sourceCodeSample || "",
                          sourceCodeLanguage:
                            contentItem.sourceCodeLanguage || "",
                            benchmarkTotalRequests: contentItem.benchmarkTotalRequests,
                            benchmarkSuccessPercentage: contentItem.benchmarkSuccessPercentage,
                            benchmarkResults: contentItem.benchmarkResults
                        });
                      }}
                    >
                      <Typography>{contentItem.title}</Typography>
                    </Box>
                  ))}
                </AccordionDetails>
              </Accordion>
            ))}
          </Box>
        </Grid>
        <Grid item xs={12} md={9}>
          <Box sx={{ border: "1px solid #ccc", padding: 2 }}>
            <APICaller
              method={selectedAPI.method}
              endpoint={selectedAPI.endpoint}
              details={selectedAPI.details}
              inputs={selectedAPI.inputs}
              setSiteName={setSiteName}
              setIsBreakingDown={setIsBreakingDown}
              sourceCodeLanguage={selectedAPI.sourceCodeLanguage}
              sourceCodeSample={selectedAPI.sourceCodeSample}
              benchmarkTotalRequests= {selectedAPI.benchmarkTotalRequests}
              benchmarkSuccessPercentage= {selectedAPI.benchmarkSuccessPercentage}
              benchmarkResults = {selectedAPI.benchmarkResults}
            />
          </Box>
        </Grid>
      </Grid>
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <Box
          sx={{
            width: "80%",
            bgcolor: "background.paper",
            borderRadius: 2,
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography variant="h6" component="h2" gutterBottom>
            API Explorer Components
          </Typography>
          <Typography variant="body1">
            These are some of the React components used to make this API Explorer. Each component is made with built-in styling and minimal modularity for readability on this page.
          </Typography>
          <APIExplorerComponents />
        </Box>
      </Modal>
    </Box>
  );
}
