import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Container,
  Typography,
  Box,
  Chip,
  Card,
  CardContent,
  Link,
} from "@mui/material";
import Lightbox from "react-awesome-lightbox";
import "react-awesome-lightbox/build/style.css"; // Include the Lightbox styles
import { projectData } from "../projects";
import CodeSnippet from "../components/CodeSnippet";

const ProjectDetailsPage = () => {
  const { id } = useParams(); // Fetch the project ID from the URL

  const project = projectData.find(
    (proj) => proj.title === id.replace(/-/g, " ")
  );

  const [isLightboxOpen, setIsLightboxOpen] = useState(false);
  const [lightboxImages, setLightboxImages] = useState([]);
  const [lightboxIndex, setLightboxIndex] = useState(0);

  useEffect(() => {
    const timer = setTimeout(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }, 100); // Adjust timing if needed
    return () => clearTimeout(timer);
  }, []);

  if (!project) {
    return (
      <Container>
        <Typography variant="h4" gutterBottom>
          Project Not Found
        </Typography>
        <Typography>
          The project you are looking for does not exist. Please go back to the
          Projects page.
        </Typography>
      </Container>
    );
  }

  const handleImageClick = (index) => {
    const images = [project.mainImage, ...project.images];
    setLightboxImages(images);
    setLightboxIndex(index);
    setIsLightboxOpen(true);
  };

  return (
    <Container>
      {/* Title */}
      <Typography variant="h3" style={{ marginBottom: "15px" }}>
        {project.title}
      </Typography>
      {/* Technologies Used */}
      {project.tags?.length > 0 && (
        <div style={{ marginBottom: "15px" }}>
          {project.tags.map((tech) => (
            <Chip
              key={tech}
              label={tech}
              sx={{
                margin: "3px",
                "&:hover": {
                  bgcolor: "success.main",
                  color: "white",
                },
              }}
            />
          ))}
        </div>
      )}

      {/* Thumbnail - Centered and Larger */}
      <Box mb={4} display="flex" justifyContent="center">
        {project.videoUrl ? (
          <video src={project.videoUrl} controls style={{ width: "100%" }} />
        ) : (
          <img
            src={project.mainImage}
            alt="Main Project"
            style={{
              maxWidth: "100%",
              cursor: "pointer",
              boxShadow: "1px 1px 3px 3px rgb(0 0 0 / 20%)",
            }}
            onClick={() => handleImageClick(0)}
          />
        )}
      </Box>

      {/* Additional Images */}

      {/* Architecture */}
      {project.images?.length > 0 && (
        <Card variant="outlined" sx={{ mb: 4, p: 2 }}>
          <Typography variant="h5" gutterBottom>
            Project Images
          </Typography>
          <Box display="flex" flexWrap="wrap" gap={2}>
            {project.images.map((image, index) => (
              <img
                key={index}
                src={image}
                alt={`Screenshot ${index + 1}`}
                style={{
                  maxWidth: "125px",
                  maxHeight: "75px",
                  cursor: "pointer",
                  boxShadow: "3px 3px 3px 3px rgb(0 0 0 / 20%)",
                }}
                onClick={() => handleImageClick(index + 1)}
              />
            ))}
          </Box>
        </Card>
      )}

      {/* Render Lightbox if open */}
      {isLightboxOpen && (
        <Lightbox
          images={lightboxImages.map((src) => ({ url: src }))}
          startIndex={lightboxIndex}
          onClose={() => setIsLightboxOpen(false)}
        />
      )}

      

      {/* Overview Card */}
      <Card variant="outlined" sx={{ mb: 4, p: 2 }}>
        <Typography variant="h5" gutterBottom>
          Overview
        </Typography>
        <Typography variant="body1">{project.description}</Typography>
      </Card>

            {/* Details Card */}
            <Card variant="outlined" sx={{ mb: 4, p: 2 }}>
        <Typography variant="h5">
          Details
        </Typography>
        {project.details.map((detail, index) => (
          <Typography style={{marginTop: '15px'}} variant="body1">{detail}</Typography>
        ))}
      </Card>

      {project.projectGoals?.length > 0 && (
        // Ensure you wrap the entire block with parentheses for clarity
        <Card variant="outlined" sx={{ mb: 4, p: 2 }}>
          <Typography variant="h5" gutterBottom>
            Project Requirements
          </Typography>
          {project.projectGoals.map((goal, index) => (
            <Typography key={index} variant="body2" paragraph>
              <strong>{index + 1}:</strong> {goal}
            </Typography>
          ))}
        </Card>
      )}

      {/* Features */}
      {project.features?.length > 0 && (
        <Card variant="outlined" sx={{ mb: 4, p: 2 }}>
          <Typography variant="h5" gutterBottom>
            Key Features
          </Typography>
          <ul>
            {project.features.map((feature, index) => (
              <li key={index}>
                <Typography variant="body2">
                  <b>Feature {index + 1}:</b> {feature}
                </Typography>
              </li>
            ))}
          </ul>
        </Card>
      )}

      {/* Code Snippets */}
      {project.codeSnippets?.length > 0 && (
        <Card variant="outlined" sx={{ mb: 4, p: 2 }}>
          <Typography variant="h5" gutterBottom>
            Code Snippets From Project
          </Typography>

          {project.codeSnippets.map((code, index) => (
            <CodeSnippet
              language={code.language}
              code={code.snippet}
              filename={code.filename}
            />
          ))}
        </Card>
      )}

      {/* Lessons Learned & Future Plans 
      <Card variant="outlined" sx={{ mb: 4, p: 2 }}>
        <Typography variant="h5" gutterBottom>
          Project Reflections
        </Typography>
        Some Reflections about the projdect.
      </Card>
*/}
      {/* Source Code / Links 
      {project.link?.length > 0 && (
        <Box mt={4} sx={{ marginBottom: "15px" }}>
          <Typography variant="h5" gutterBottom>
            Libraries Used
          </Typography>

          {project.link.map((link) => (
            <div style={{ width: "100%", marginTop: "3px" }}>
              <Link href={link} target="_blank">
                {link}
              </Link>
            </div>
          ))}
        </Box>
      )}*/}
    </Container>
  );
};

export default ProjectDetailsPage;
