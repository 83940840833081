import React, { useState } from "react";
import {
  Container,
  TextField,
  Button,
  Typography,
  Box,
  Paper,
  Checkbox,
  FormControlLabel,
  Grid,
} from "@mui/material";
import ReCAPTCHA from "react-google-recaptcha"; // Import reCAPTCHA
import * as settings from "../settings";
const ContactPage = () => {
  const initialFormData = {
    name: "",
    organization: "",
    email: "",
    phone: "",
    message: "",
    pgpMessage: "",
    userPublicKey: "",
    usePGP: false,
    options: {
      mvpDevelopment: false,
      technicalCoFounder: false,
      hireFreelanceDeveloper: false,
      customWebApplication: false,
      apiIntegration: false,
      performanceOptimization: false,
      generalInquiry: false,
    },
  };

  const formatPhoneNumber = (value) => {
    // Remove all non-numeric characters
    const cleaned = value.replace(/\D/g, "");

    // Stop input if length exceeds 10
    if (cleaned.length > 10) {
      return null;
    }

    // Format based on the number of digits
    if (cleaned.length === 0) {
      return "";
    }
    if (cleaned.length <= 3) {
      return `(${cleaned}`;
    }
    if (cleaned.length <= 6) {
      return `(${cleaned.slice(0, 3)}) ${cleaned.slice(3)}`;
    }
    return `(${cleaned.slice(0, 3)}) ${cleaned.slice(3, 6)}-${cleaned.slice(
      6
    )}`;
  };

  const [formData, setFormData] = useState(initialFormData);

  const [captchaToken, setCaptchaToken] = useState(null);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (name === "phone") {
      const input = e.target;
      const start = input.selectionStart;
      const end = input.selectionEnd;

      // Format phone number
      const rawValue = input.value;
      const formattedPhone = formatPhoneNumber(value);

      // Prevent further input if the limit is exceeded
      if (formattedPhone === null) {
        return;
      }

      // Set the formatted value and adjust cursor
      setFormData((prevData) => ({
        ...prevData,
        [name]: formattedPhone,
      }));

      // Restore cursor position after formatting
      setTimeout(() => {
        const adjustment = formattedPhone.length - rawValue.length;
        input.setSelectionRange(start + adjustment, end + adjustment);
      }, 0);
    } else if (name in formData.options) {
      // Update options checkboxes
      setFormData((prevData) => ({
        ...prevData,
        options: {
          ...prevData.options,
          [name]: checked,
        },
      }));
    } else {
      // Update other fields
      setFormData((prevData) => ({
        ...prevData,
        [name]: type === "checkbox" ? checked : value,
      }));
    }
  };

  const handleCaptchaChange = (token) => {
    setCaptchaToken(token); // Update token when CAPTCHA is solved
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!captchaToken) {
      alert("Please complete the reCAPTCHA challenge.");
      return;
    }

    console.log("Form submitted:", formData);

    const response = await fetch(`${settings.APIUrl}/submit-contact-form`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ formData }),
    });
    console.log(response);

    // Reset form and CAPTCHA
    alert("Message sent! Thank you for reaching out.");
    setFormData(initialFormData);
    setCaptchaToken(null);
  };

  return (
<Container maxWidth="lg">
  <Paper elevation={3} sx={{ padding: 3, marginBottom: 2 }}>
    <Typography variant="h4" align="center" gutterBottom>
      Contact Me
    </Typography>
    <Box sx={{ textAlign: "center" }}>
      <Typography variant="body1">
        Please use this form to submit requests. I will get back to you within 24 hours.
      </Typography>
    </Box>

    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        marginTop: 2,
      }}
    >
      <Grid container spacing={3}>
        {/* Left Column */}
        <Grid item xs={12} md={6}>
          <TextField
            label="Name"
            name="name"
            variant="outlined"
            value={formData.name}
            onChange={handleInputChange}
            required
            fullWidth
          />
          <TextField
            label="Organization"
            name="organization"
            variant="outlined"
            value={formData.organization}
            onChange={handleInputChange}
            fullWidth
            sx={{ mt: 2 }}
          />
          <TextField
            label="Email"
            name="email"
            type="email"
            variant="outlined"
            value={formData.email}
            onChange={handleInputChange}
            required
            fullWidth
            sx={{ mt: 2 }}
          />
          <TextField
            label="Phone Number"
            name="phone"
            type="tel"
            variant="outlined"
            value={formData.phone}
            onChange={handleInputChange}
            placeholder="Enter your phone number"
            required
            fullWidth
            sx={{ mt: 2 }}
          />
                    {/* Send message with PGP Checkbox */}
                    <FormControlLabel
            control={
              <Checkbox
                checked={formData.usePGP}
                onChange={handleInputChange}
                name="usePGP"
              />
            }
            label="Send PGP Encrypted Message"
          />

          {/* Message Field */}
          {!formData.usePGP && (
            <TextField
              label="Message"
              name="message"
              variant="outlined"
              multiline
              rows={4}
              placeholder="Tell me about your project, timeline, or ask any questions."
              value={formData.message}
              onChange={handleInputChange}
              required
              fullWidth
            />
          )}

          {/* PGP Fields */}
          {formData.usePGP && (
            <>
              <TextField
                label="Public PGP Key"
                name="publicPGPKey"
                variant="outlined"
                value={settings.PGPKEY}
                InputProps={{
                  readOnly: true,
                }}
                multiline
                rows={4}
                fullWidth
                sx={{ mt: 2 }}
              />
              <TextField
                label="Your Public PGP Key (If encrypted response desired)"
                name="userPublicKey"
                variant="outlined"
                multiline
                rows={4}
                value={formData.userPublicKey}
                onChange={handleInputChange}
                fullWidth
                sx={{ mt: 2 }}
              />
              <TextField
                label="Your PGP Encrypted Message"
                name="pgpMessage"
                variant="outlined"
                multiline
                rows={4}
                value={formData.pgpMessage}
                onChange={handleInputChange}
                required
                fullWidth
                sx={{ mt: 2 }}
              />
            </>
          )}
        </Grid>

        {/* Right Column */}
        <Grid item xs={12} md={6}>

          {/* Checkboxes Section */}
          <Grid container spacing={2} sx={{ mt: 2 }}>
            {Object.keys(formData.options).map((key) => (
              <Grid item xs={12} md={6} key={key}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formData.options[key]}
                      onChange={handleInputChange}
                      name={key}
                    />
                  }
                  label={
                    key === "mvpDevelopment"
                      ? "Build a Minimum Viable Product (MVP)"
                      : key === "technicalCoFounder"
                      ? "Technical Co-Founder Inquiry"
                      : key === "hireFreelanceDeveloper"
                      ? "Hire a Freelance Developer"
                      : key === "customWebApplication"
                      ? "Custom Web or Mobile Application"
                      : key === "apiIntegration"
                      ? "API Integration or Optimization"
                      : key === "performanceOptimization"
                      ? "Performance Optimization or Bug Fixes"
                      : "General Inquiry or Consultation"
                  }
                />
              </Grid>
            ))}
          </Grid>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mt: 2,
            }}
          >
            <ReCAPTCHA
              sitekey={settings.RECAPTCHATOKEN}
              onChange={handleCaptchaChange}
            />
          </Box>

          <Button
            type="submit"
            variant="contained"
            color="primary"
            sx={{ mt: 2, width: "100%" }}
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    </Box>
  </Paper>
</Container>

  );
};

export default ContactPage;
