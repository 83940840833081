import {
    getImageCode,
    getCallRecordingSamples,
    checkIpWhoisSource,
    getMapImageSample,
    createGiftCertificateSample,
    validatePasswordSample,
    sampleFormsSample,
    changeSiteNameSample,
    breakdownSample,
  } from "./sourceCodeSamples";

import * as benchmarks from "./benchmarkResults";

export const accordionData = [
    {
      title: "GET",
      description: "Retrieve Data",
      content: [
        {
          title: "Get Sample Data",
          details: "Gets a list of sample call recording data.",
          endpoint: "/call-recordings",
          sourceCodeSample: getCallRecordingSamples,
          sourceCodeLanguage: "python",
          benchmarkTotalRequests: 100000,
          benchmarkSuccessPercentage: 100,
          benchmarkResults: benchmarks.callRecordings

        },
        {
          title: "Get Image",
          details: "Gets and displays a sample base64 encoded image.",
          sourceCodeSample: getImageCode,
          sourceCodeLanguage: "python",
          endpoint: "/get-image",
          benchmarkTotalRequests: 100000,
          benchmarkSuccessPercentage: 100,
          benchmarkResults: benchmarks.getImage
        },
        {
          title: "Check IP Whois",
          details:
            "This will get the WHOIS information about your IP. This showcases the use of a 3rd party API service utilized by our own API.",
          endpoint: "/check-ip-whois",
          sourceCodeSample: checkIpWhoisSource,
          sourceCodeLanguage: "python",
        },
        {
          title: "Get Map Image From Address",
          details:
            "Enter an address or place name to receive a map of the location. This showcases the use of an authenticated 3rd party API service utilized by our own API.",
          endpoint: "/get-map-image",
          inputs: [
            {
              type: "text",
              label: "address",
              defaultValue: "Tempo Cafe, Chicago, IL 60611",
            },
          ],
          sourceCodeSample: getMapImageSample,
          sourceCodeLanguage: "python",
          
        },
      ],
    },
    {
      title: "POST",
      description: "Create New Data",
      content: [
        {
          title: "Create Gift Certificate",
          endpoint: "/create-gift-certificate",
          details:
            "Creates a sample gift certificate. A template image is loaded and text is superimposed onto the image.",
          inputs: [
            {
              type: "text",
              label: "recipient_name",
              defaultValue: "Satisfied Customer",
            },
            {
              type: "text",
              label: "sender_name",
              defaultValue: "Qualified Sender",
            },
            {
              type: "text",
              label: "amount",
              defaultValue: "50.00",
            },
          ],
          sourceCodeSample: createGiftCertificateSample,
          sourceCodeLanguage: "python",
          benchmarkTotalRequests: 100000,
          benchmarkSuccessPercentage: 100,
          benchmarkResults: benchmarks.giftCertificate
        },
        {
          title: "Validate Password",
          endpoint: "/validate-password",
          details:
            "Validates password for the following requirements: Must contain at least one uppercase letter (A-Z) Must contain at least one lowercase letter (a-z) Must contain at least one digit (0-9) Must contain at least one special character (e.g., !@#$%^&*()-_=+) Must be at least 8 characters long Must not contain spaces.",
          inputs: [
            {
              type: "text",
              label: "password",
              defaultValue: "&here4ndNow😊",
            },
          ],
          sourceCodeSample: validatePasswordSample,
          sourceCodeLanguage: "python",
          benchmarkTotalRequests: 100000,
          benchmarkSuccessPercentage: 100,
          benchmarkResults: benchmarks.validatePassword
        },
        {
          title: "Sample Forms",
          endpoint: "/sample-forms",
          details: "Returns the data sent to it.",
          inputs: [
            {
              type: "text",
              label: "random_data",
              defaultValue: "18475050101",
            },
            {
              type: "radio",
              label: "validation_level",
              options: [
                { label: "basic", value: "basic" },
                { label: "advanced", value: "advanced" },
              ],
              defaultValue: "basic",
            },
            {
              type: "checkbox",
              label: "include_country_code",
              defaultValue: true,
            },
            {
              type: "dropdown",
              label: "region",
              options: [
                { label: "north_america", value: "north_america" },
                { label: "europe", value: "europe" },
                { label: "asia", value: "asia" },
              ],
              defaultValue: "north_america",
            },
          ],
          sourceCodeSample: sampleFormsSample,
          sourceCodeLanguage: "python",
          benchmarkTotalRequests: 100000,
          benchmarkSuccessPercentage: 100,
          benchmarkResults: benchmarks.sampleForms
        },
      ],
    },
    {
      title: "PATCH",
      description: "Update Existing Data",
      content: [
        {
          title: "Change This Site Name",
          endpoint: "/change-site-name",
          details:
            "Changes the name of this website. Uses API to validate input and receives an action in the response, triggering custom React code.",
          inputs: [
            {
              type: "text",
              label: "site_name",
              defaultValue: "SomeRandomSite",
            },
          ],
          sourceCodeSample: changeSiteNameSample,
          sourceCodeLanguage: "python",
        },
      ],
    },
    {
      title: "DELETE",
      description: "Delete Data",
      content: [
        {
          title: "Delete This website",
          endpoint: "/delete-website",
          details:
            "Deletes this entire website. WARNING: This action cannot be undone.",
          sourceCodeSample: breakdownSample,
          sourceCodeLanguage: "javascript",
        },
      ],
    },
  ];