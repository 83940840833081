import React from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism'; // Use a terminal-like theme

const DebianCode = ({ language = "shell", code = "" }) => {
  return (
    <div style={{ 
      border: '1px solid #4e4e4e', 
      borderRadius: '4px', 
      overflow: 'hidden', 
      fontFamily: 'monospace', 
      backgroundColor: '#1e1e1e', 
      color: '#dcdcdc' 
    }}>
      <SyntaxHighlighter
        language={language}
        style={vscDarkPlus} // Updated theme for terminal-like appearance
        wrapLongLines
        customStyle={{
          backgroundColor: '#1e1e1e', 
          color: '#dcdcdc',
          margin: 0,
          padding: '16px'
        }}
      >
        {code}
      </SyntaxHighlighter>
    </div>
  );
};

export default DebianCode;
