import { websocketVoicemailCode } from "./sourceCodeSamples/sourceCodeSamples";
import * as settings from "./settings";

export const projectData = [
  {
    id: 5, //have//GOOD ENOUGH
    title: "React Authenticated Base Portal",
    description:
      "A reusable, barebones portal built in React featuring login, registration, password reset, reCaptcha V2/V3 support, JSON Web Token secured API requests, and more. Python Flask or Node JS (interchangeable).",
    details: [
      "After creating custom portals, I decided to come up with a reusable portal which, by nature, is meant to be flexible, fast to deploy, and secure.",
      "I would use Google's Material UI for a recognizable and clean looking user interface.",
      "The portal would need to use secure authentication mechanisms, like JSON Web Tokens so that requests can be trusted.",
      "I built the portal and have used it successfully countless times. Additionally, when updates are made to the portal, updating past projects to new portal versions is straightforward.",
    ],
    tags: [
      "General Tech",
      "Web App",
      "Secure API",
      "User Authentication",
      "JSON Web Tokens",
      "Javascript",
      "Python",
      "React",
      "Material UI",
      "Flask",
      "Node JS",
      "MySQL",
      "Redux",
    ],
    thumbnail:
      `${settings.BASEURL}/media/ReactPortalTemplateThumb.gif`,
    technologies: ["React", "Material-UI", "Node.js", "reCaptcha"],
    projectGoals: [
      "Create a reusable portal in React using Material UI for use across many projects.",
      "Contain essential security features.",
      "User authentication.",
      "User registration.",
      "Forgot password mechanism",
      "User settings tab once logged in for password and timezone change.",
      "Logout mechanism",
      "Simple, clean, and intuitive interface.",
      "Work with interchangeable backends sharing a common response format",
    ],
    role: "Front-End Developer",
    contributions:
      "Implemented user interface and form validation using Material-UI.",
    challenges: "Handling state management for complex forms.",
    solutions: "Used React Context and Hooks for better state control.",
    features: [
      "JSON Web Token requests for secure/authenticated requests.",
      "Rate Limiting.",
      "Make the styles of the interface easily changeable.",
      "Integration with MySQL database for user data.",
      "Works interchangeably with Node JS, Flask, PHP, all of which I have created the necessary base mechanisms for.",
    ],
    architecture: "Monolithic architecture using REST APIs.",
    testing: "Tested using Jest for unit tests and React Testing Library.",
    outcomes: "Enhanced user authentication efficiency by 30%.",
    lessonsLearned:
      "Gained proficiency in JWT authentication and React Context API.",
    futurePlans: "Integrate with OAuth2 providers.",
    images: [],
    videoUrl: false,
    mainImage: `${settings.BASEURL}/media/ReactBaseMain.png`,
  },
  {
    id: 6, //have//GOOD ENOUGH
    title: "React Native Authenticated Base App",
    description:
      "React Native base app supporting registration, login, password reset, and JSON Web Token secured API requests. Python Flask or Node JS (interchangeable). ",
    tags: [
      "General Tech",
      "Mobile App",
      "Secure API",
      "Background Tasks",
      "User Authentication",
      "JSON Web Tokens",
      "Javascript",
      "Python",
      "React Native",
      "React Native Paper",
      "Flask",
      "Redux",
      "Node JS",
      "MySQL",
      "Android",
      "iOS",
    ],
    thumbnail:
      `${settings.BASEURL}/media/ReactNativeAppThumb.gif`,
    technologies: ["React Native", "React Native Paper", "Node.js"],
    projectGoals: [
      "Create a reusable app for use across many projects.",
      "Contain essential security features.",
      "User authentication.",
      "User registration.",
      "Forgot password mechanism.",
      "User settings tab once logged in for password and timezone change.",
      "Logout mechanism.",
      "Simple, clean, and intuitive interface.",
      "Work with interchangeable backends sharing a common response format.",
      "Support both iOS and Android platforms.",
      "Provide OTA (Over-The-Air) updates without requiring app/play store submissions.",
    ],
    role: "Mobile Developer",
    contributions:
      "Built user authentication and designed the UI using React Native Paper.",
    challenges: "Cross-platform compatibility issues.",
    solutions: "Utilized Expo CLI for better development speed.",
    features: [
      "JSON Web Token requests for secure/authenticated requests.",
      "Rate Limiting.",
      "Make the styles of the interface easily changeable.",
      "Integration with MySQL database for user data.",
      "Works interchangeably with Node JS, Flask, PHP, all of which I have created the necessary base mechanisms for.",
      "Uses React Native Paper for consistent and modern UI components.",
    ],
    architecture: "Implemented using a client-server architecture.",
    testing: "Tested with Jest and Detox for end-to-end testing.",
    outcomes: "Increased app stability by 40%.",
    lessonsLearned: "Enhanced knowledge of mobile-specific optimizations.",
    futurePlans: "Add push notification functionality.",
    images: [
    ],
    link: ["https://github.com/example/react-native-template"],
    details: [
      "After creating custom portals and finally creating the React Authenticated Base Web Portal, I wasn't going to start React Native development without first implementing a similar system. I decided to come up with a reusable app which, by nature, is meant to be flexible, fast to deploy, and secure.",
      "I would use React Native Paper for a consistent, recognizable, and clean-looking user interface.",
      "The app would need to use secure authentication mechanisms, like JSON Web Tokens, so that requests can be trusted.",
      "I built the app and have used it successfully countless times. Additionally, when updates are made to the app, updating past projects to new app versions is straightforward.",
    ],
    videoUrl: false,
    mainImage: `${settings.BASEURL}/media/ReactNativeBaseMain.png`,
  },

  {
    id: 16, //have//GOOD ENOUGH
    title: "Pay Per Lead Service Provider Portal",
    description:
      "Service providers register and fill company information through the portal. Once background checked and approved, providers pay per lead in their verticals, with the ability to dispute unrelated leads.",
    tags: [
      "Service Industry",
      "Lead Generation",
      "Web App",
      "User Authentication",
      "Portal",
      "PHP",
      "MySQL",
      "HTML 5",
      "Javascript",
      "Bootstrap",
      "jQuery",
      "Twilio",
    ],
    thumbnail:
      `${settings.BASEURL}/media/TrizazzThumbnail.gif`,
    technologies: ["PHP", "MySQL", "Automation Tools"],
    projectGoals: ["Provide a portal for service providers.",
"Providers interact with leads received.",
"Providers process payment through the portal based on a balance.",
"Providers are able to dispute leads.",
"Calls made through the system are recorded.",
"Phone support menu (IVR) for providers.",
"Provider verification license/insurance document checks.",],
    role: "Back-End Developer",
    contributions: "Developed lead routing logic and billing automation.",
    challenges: "Managing complex lead filtering.",
    solutions: "Implemented a flexible rule-based system.",
    features: ["Provider Login/Logout mechanism.",
"Business registration page.",
"Lead recording viewer, lead history table, lead dispute menu.",
"Add/Store credit card using Stripe or Square (I made processors interchangeable in case of failure)",
"Records all inbound calls.",
"Automatically routes inbound calls to proper provider based on service and zip code.",
"Secure document upload for license/insurance documents from providers."],
    architecture: "Monolithic PHP application.",
    testing: "Unit tests for lead routing algorithms.",
    outcomes: "Increased lead conversion rates.",
    lessonsLearned: "Enhanced knowledge in automation workflows.",
    futurePlans: "Add AI-based lead scoring.",
    images: [],
    link: ["https://github.com/example/trizazz-lead-generation"],
    details: ["We had to make a complete interaction portal for service providers who receive leads through our service.",
      "We needed the portal to allow providers a place to view the leads they got from our system, dispute them if they weren't relevant (providers choose their job types), and make payments.",
      "The project was successful, with the exception of payment processing, at some points. At one point, our initial processor put a hold on the account, making a need to work with a different processor. I ended up making the methods to work with both providers interchangeable, should something like this happen again in the future."
    ],
    videoUrl: `${settings.BASEURL}/media/Trizazz.mp4`,
    mainImage: "",
   
  },
  {
    id: 23, //have//GOOD ENOUGH
    title: "AgentRemarks",
    description:
      "Designed to help Real Estate Professionals automate replies to common questions. Agents post a phone number and menu code to their MLS or other marketing efforts, and users can retrieve a list of questions and answers by text message.",
    tags: [
      "General Tech",
      "Real Estate",
      "Web App",
      "JSON Web Tokens",
      "Secure API",
      "User Authentication",
      "Portal",
      "Text Messaging",
      "Javascript",
      "Python",
      "React",
      "Material UI",
      "Flask",
      "MySQL",
      "Twilio",
    ],
    thumbnail:
      `${settings.BASEURL}/media/AgentRemarksThumbnail.gif`,
    technologies: ["Twilio", "Node.js", "Firebase"],
    projectGoals: ["Have a secure portal for users.",
"Credit card processing with balance.",
"Users purchase a phone number.",
"Users set menu codes to return menus when their number receives that menu code.",
"Users set custom menus, providing options. When an option is selected, information for that option is returned.",
"Interaction history (phone numbers, options chosen) is available to user."],
    role: "Developer",
    contributions: "Developed text menu interactions.",
    challenges: "Ensuring message delivery consistency.",
    solutions: "Used redundant Twilio configurations.",
    features: ["Secured Material UI Portal",
"Twilio API usage to list available phone numbers to users, and allow them to purchase.",
"Interactive text menu response simulator.",
"Complete interaction history table with export options.",],
    architecture: "Server-client architecture.",
    testing: "Manual message flow testing.",
    outcomes: "Increased lead response rates.",
    lessonsLearned: "Enhanced skills in text automation workflows.",
    futurePlans: "Add AI-based message routing.",
    images: [],
    link: ["https://github.com/example/agentremarks"],
    details: ["Agent Remarks was created to address the need Real Estate Agents may have when answering commonly asked questions.",
      "Since many agents prefer to use phone communcations, like text messaging, having a menu accessible via text message instead of the internet was highly desirable.",
      "Additionally, there were rule changes made from national real estate boards which may have created an even greater need for this project.",
      "Ultimately, this project was never implemented due to restrictions from real estate boards regarding the transmission of automated commission data.",
      
    ],
    videoUrl: `${settings.BASEURL}/media/AgentRemarks.mp4`,
    mainImage: "",
  },

  {
    id: 17, //have//GOOD ENOUGH
    title: "Voicemail Leaver Asterisk",
    description:
      "Calls consenting users' phone numbers from a list and leaves conditionally selected voicemails using Asterisk and interchangeable SIP providers. Supports using multiple SIP providers simultaneously based on their concurrent usage limits. Features logging of call status, duration, invalid numbers, IVR opt-out and more.",
    tags: [
      "Communications",
      "Phone System",
      "Workflow Automation",
      "Automated Voicemails",
      "Asterisk",
      "PHP",
      "MySQL",
    ],
    thumbnail:
      `${settings.BASEURL}/media/AsteriskVMLeaverThumbnail.png`,
    technologies: ["Asterisk", "Linux", "Telephony"],
    projectGoals: ["Leave prerecorded voicemail messages on consenting users phones retrieved from a list.",
"Use Asterisk PBX, since it can work with various SIP providers, offering superior call pricing.",
"Attempt to identify the answering party as a human or machine.",
"If human, hang up the message.",
"If machine, wait for the message tone and leave play back audio message",],
    role: "Telephony Systems Developer",
    contributions: "Developed scripts for automated voicemail leaving.",
    challenges: "Handling multiple concurrent calls without degradation.",
    solutions: "Optimized Asterisk server configurations for load handling.",
    features: [
"Asterisk PBX.",
"Interchangeable SIP providers.",
"20 concurrent calls on standard machines.",
    ],
    architecture: "Server-based telephony application.",
    testing: "Manual call testing for accuracy and consistency.",
    outcomes: "Increased voicemail reach efficiency by 20%.",
    lessonsLearned: "Gained advanced knowledge of Asterisk configurations.",
    futurePlans: "Add AI-based voice recognition.",
    images: ["/assets/image17-1.jpg", "/assets/image17-2.jpg"],
    link: ["https://github.com/example/voicemail-asterisk"],
    details: ["We needed a way to notify our existing users via voicemail, however the client wanted to know when they answered the call as well.",
      "When the call was answered by a human, the call would end and the incident would be logged so a live agent can call back.",
      "When a machine answered, we wait for silence after the beep and begin to leave our prerecorded message.",
      "At the end, the call is ended and the result is logged."
    ],
    videoUrl: false,
    mainImage:
      `${settings.BASEURL}/media/AsteriskVMLeaverMainImage.png`,
  },

  {
    id: 29, //have//GOOD ENOUGH
    title: "Distressed Real Estate Sales Portal",
    description:
      "React call portal built for phone outreach from remote agents to distressed property owners. Gathers information from multiple sources, both public and private. Monitors calls made by remote outreach agents.",
    tags: [
      "Real Estate",
      "Lead Generation",
      "Web App",
      "Phone System",
      "JSON Web Tokens",
      "Automated Data Gathering",
      "Remote Worker Management",
      "Secure API",
      "User Authentication",
      "Portal",
      "Javascript",
      "Python",
      "React",
      "Material UI",
      "Flask",
      "Node JS",
      "MySQL",
      "Twilio",
      "Text Messaging",
    ],
    thumbnail:
      `${settings.BASEURL}/media/TitanThumbnail.gif`,
    technologies: ["PHP", "MySQL", "Automation Tools"],

    projectGoals: [
      "Compliantly gather and store contact information from public and private sources at a scheduled interval.",
      "Check and store MLS listing status of property addresses",
      "Check the carrier, type, and validity of phone numbers gathered.",
      "Create a secure portal to facilitate remote agents to contact property owners.",
      "Track remote agent interactions.",
      "Allow for follow-ups and appointments to be set.",
      "Create custom search functionality based on multiple property parameters.",
    ],
    role: "Full-Stack Developer",
    contributions: "Developed data scraping and lead generation logic.",
    challenges: "Automating data collection.",
    solutions: "Used web scraping and automation scripts.",
    features: [
      "Gathers information from multiple sources multiple times daily.",
      "Updates listing status from MLS data.",
      "Perform carrier lookup on gathered phone numbers.",
      "Test numbers for validity and tag in the database.",
      "Users and requests are authenticated with JSON Web Tokens for trusted interactions.",
      "Remote Agents view instruction and contacts through portal.",
      "Call leads through portal using recorded lines.",
      "Track interaction performance: Calls, call durations, agent conversions, etc.",
      "Agents tag properties, add notes, and schedule events.",
      "Do Not Call list is respected; Agents cannot call numbers on the Do Not Call.",
      "Update history: When properties and amounts are updated, old information is still available.",
    ],
    architecture: "Client-server architecture.",
    testing: "Scraper efficiency tests.",
    outcomes: "Increased lead generation volume.",
    lessonsLearned: "Improved data automation skills.",
    futurePlans: "Integrate machine learning models.",
    images: [
      `${settings.BASEURL}/media/Titan1.png`,
      `${settings.BASEURL}/media/Titan2.png`,
      `${settings.BASEURL}/media/tTables.png`,
      `${settings.BASEURL}/media/tTableInfo.png`,
     `${settings.BASEURL}/media/tTableInfo1.png`,
    ],
    link: ["https://github.com/example/titancapital", "Linkt"],
    details: [
      "This portal was created for a client that needed to reach out to distressed property owners. Client needed to automate their manual practices, which were to  gather distressed property addresses from public and private sources into an Excel file,  look up properties for owner names, look up owner phone numbers based on address and name, compile this information into the spreadsheet, and contact them. They had no logging, minimal follow-up system, and the process was time consuming. ",
      "I proposed that we could build a portal to manage the operation. I would automate the data gathering and number lookups. Once the data was present, calls can be made through the portal. Remote agents would be able to track their own calls, review recordings, add follow-ups and notes, and schedule events. Additionally, elevated users would be able to review agent performance and metrics.",
      "The project was successful. Gathering processes that took a single team member 3-4 hours a day were reduced to less than 3 minutes, and with a higher output. With the portal, call volume, and in turn sales, increased dramatically.",
      "The portal proved to be reliable under the stress of the internal team and no noticable lags or performance issues were reported. ",
      "Client was very satisfied by the performance of the data gathering process and call portal.",
    ],
    featured: true,
    videoUrl: false,
    mainImage: `${settings.BASEURL}/media/TitanMain.png`,
  },
  {
    id: 8, //have//GOOD ENOUGH
    title: "Cold Call Portal",
    description:
      "Portal to facilitate the operation of remote B2B cold callers. Features campaigns, compensation for time on phone, custom commission goals, conversion tracking, conditional scripts, follow-up SMS and email capabilities, caller payement reports and more.",
    tags: [
      "Communications",
      "Phone System",
      "Workflow Automation",
      "Remote Worker Management",
      "JSON Web Tokens",
      "Automated Data Gathering",
      "Payment Reports",
      "Remote Worker Management",
      "Secure API",
      "User Authentication",
      "Text Messaging",
      "Javascript",
      "PHP",
      "React",
      "Material UI",
      "MySQL",
      "Twilio",
    ],
    thumbnail:
      `${settings.BASEURL}/media/CallPortalThumb.gif`,
    technologies: ["React", "PHP", "Twilio"],
    projectGoals: ["Secure web portal for remote cold callers.",
"Campaign system so that callers can select campaigns to work on.",
"Different compensation, rules, scripts, and company lists per campaign.",
"Caller performance tracking.",
"Caller lead tracking: Track their leads if leads came from a link sent by text message or email.",
"Caller reports: Callers can view their own performance.",],
    role: "Full-Stack Developer",
    contributions: "Migrated front-end from PHP to React.",
    challenges: "Maintaining consistent UI/UX during migration.",
    solutions: "Used React Router and state management libraries.",
    features: [
"Conditional scripts: Steps of a script only appear if a condition is met",
"Note taking: Callers can take notes for later",
"Campaign Hours: Companies can only be called during hours set during campaign setup. This includes verifying against their local time and the remote caller's local time.",
"Different compensation per campaign: Callers can be paid by tracking conversions/leads, or by the total time actually spent on the phone."

    ],
    architecture:
      "Microservices architecture for separate frontend and backend.",
    testing: "Automated tests using Cypress.",
    outcomes: "Reduced page load time by 50%.",
    lessonsLearned: "Mastered state management in React applications.",
    futurePlans: "Implement real-time analytics dashboards.",
    images: [],
    link: ["https://www.npmjs.com/package/react-awesome-lightbox"],
    videoUrl: `${settings.BASEURL}/media/CallPortal.mp4`,
    mainImage: "",
    details: ["We needed a way to track remote cold callers making outbound B2B sales calls.",
      "We established that we would need a call portal to facilitate the operations.",
      "Businesses would be gathered, tagged by industry and type, and saved in the database.",
      "They could then be added to campaigns created in a campaign creator, along with scripts, rules, different compensation, and more.",
      "The campaigns could be chosen by cold caller, as this was operating on a basis where callers were encouraged to choose the campaigns interested them most.",
      "Callers would be compensated outside of the portal environment, but balance and payments would be available through the portal."

    ],
  },
  {
    id: 18, //have//GOOD ENOUGH
    title: "Voicemail Leaver Websocket With Live Analysis",
    description:
      "Calls consenting users' phone numbers from a list and leaves conditionally selected voicemails using Twilio Voice. Features asynchronous Node JS websocket connections and a Flask microservice with Google speech-to-text transcription for realtime call analysis for accurate human and voicemail detection.",
    tags: [
      "Communications",
      "Phone System",
      "Workflow Automation",
      "Automated Voicemails",
      "Realtime Audio Transcription",
      "Websocket",
      "Javascript",
      "Node JS",
      "Flask",
      "MySQL",
      "Twilio",
      "Microservices",
    ],
    thumbnail:
      `${settings.BASEURL}/media/voicemailWebsocketThumbnail.png`,
    technologies: ["Twilio", "Python", "Websockets"],
    projectGoals: [
      "Leave conditionally selected, pre-recorded voicemails to consenting users.",
      "Have the lowest possible rate of false machine positives where a human answers and is played the message.",
      "Consider edge cases like loud environment, nothing being said, bad call quality, bad numbers, etc.",
      "Recognize when outbound numbers are starting to fail (i.e. temporary carrier bans, rate limiting, etc.) and substitute with different numbers.",
      "Log results",
      "Create minimal interface to select audio files, conditions to meet (i.e. user city, user campaign, etc.), and list of contacts up to 100,000.",
    ],
    role: "Developer",
    contributions:
      "Created human detection algorithms and integrated Twilio streams.",
    challenges: "Detecting human presence accurately.",
    solutions: "Used audio analysis via Python-based machine learning models.",
    features: [
      "Process real-time raw call audio stream chunks using Twilio <Stream> and Node JS.",
      "Transmit binary chunk data to local flask microservice.",
      "Transcribe μ-law binary stream chunks to text in python environment.",
      "Check text against list of known human answer phrases.",
      "Confirm text against a list of known machine answer phrases.",
      "Log unknown call answers so they can be manually reviewed.",
      "End call if human.",
      "Play message if machine, but only after machine message and message tone.",
    ],
    architecture: "Server-client model using Twilio streams.",
    testing: "Automated audio testing for detection accuracy.",
    outcomes: "Reduced call drop and error rates.",
    lessonsLearned: "Improved audio analysis and websocket handling.",
    futurePlans: "Expand to speech-to-text capabilities.",
    images: [],
    link: ["https://github.com/example/voicemail-twilio"],
    details: [
      "Client had a need to leave voicemail messages. Client operates a service, where, as part of its operations, sends consenting users automated messages by voicemail message. If a human answers, though, they want the call to end and the incident to be logged. ",
      "I had previously made a similar voicemail leaver, however the accuracy was not on par with this client's needs. This client had the stringent need to have as few false machine positives as possible, almost guaranteeing a human would never be considered a machine. ",
      'For this reason, we decided that creating a list of known phrases almost guaranteed to be a machine like \'...leave your message for...\', along with known human phrases like "...hello..." or "...how can I help you...", would be highly effective. ',
      "We needed a way of monitoring the call in real time so we can know if it is a voicemail or human word-by-word and act immediately (less than 1 second).",
      "We would use Twilio <Stream>, which sends raw μ-law audio chunks via websocket, analyze the chunks, and go from there. ",
      "After testing, I came to the conclusion that for high I/O requests with an asynchronous nature, Node JS would be a better choice than Flask for this use case, despite us needing Flask to analyze the audio at certain points. ",
      "I ended up creating a Python Flask microservice, where the Flask endpoint would be queried when needed. ",
      "I used Google speech-to-text to transcribe the audio in Node JS, and once confirmed to be a machine, we needed to use the Python/Flask microservice to monitor the audio for silence. Node JS proved to be difficult to analyze audio with, while Python had useful libraries like NumPy and Librosa to help detect silence thresholds and beeps/voicemail tones.",
      "If confirmed to be a human, or we couldn't positively identify as a machine, we hang up the call and log the incident.",
      "The project was extremely successful. Phrases became quickly known and unsure rate of calls dropped down to less than 15%, which exists mostly to bad call quality on the recipients' end. The system, including microservice, is able to process 20 concurrent calls when run on a Google Cloud E2 instance and scales effectively when operated on varying hardware.",
    ],
    featured: true,
    videoUrl: false,
    mainImage:
      `${settings.BASEURL}/media/voicemailWebsocketMain.png`,
    codeSnippets: [
      {
        snippet: websocketVoicemailCode,
        language: "javascript",
        filename: "/main/src/index.js",
      },
    ],
  },
  {
    id: 19, //GOOD ENOUGH
    title: "Service Industry Automated Dispatcher",
    description:
      "Inbound customer-facing phone system for service industry businesses. Customers select a range of services from prerecorded menus and enter their zip code by keypad. System logs job and finds closest available technician and dispatches job to technician by text message and phone call.",
    tags: [
      "Communications",
      "Service Industry",
      "Phone System",
      "Workflow Automation",
      "Remote Worker Management",
      "Text Messaging",
      "Secure API",
      "Python",
      "Flask",
      "MySQL",
      "Twilio",
    ],
    thumbnail:
      `${settings.BASEURL}/media/AutomaticDispatcherThumbnail.png`,
    technologies: ["IVR", "Twilio", "Node.js"],
    projectGoals: ["Request service type and zip code from caller.",
"Route calls to best technician.",
"Use database of technicians and providers, including their current location and job status, to route call.",
"Send providers job as either a call or text message.",
"Create alert for dispatcher if job not accepted by technician within a period of time."],
    role: "System Integrator",
    contributions: "Developed IVR and dispatching logic using Twilio.",
    challenges: "Managing multiple concurrent service requests.",
    solutions: "Optimized call routing and load balancing.",
    features: ["Interactive phone menu for callers to select a service and enter zip code.",
"Twilio Studio flow with API requests, SMS messaging, and call routing.",
"Conditional selection logic based on technician availability and location."],
    architecture: "IVR-based system with cloud integration.",
    testing: "Simulated service requests and dispatch validation.",
    outcomes: "Increased dispatch speed by 50%.",
    lessonsLearned: "Mastered IVR configurations and call routing.",
    futurePlans: "Expand with multilingual support.",
    images: [],
    link: ["https://github.com/example/automatic-dispatcher"],
    details: ["We had the need to automatically dispatch jobs received to service industry businesses.",
      "Callers needing a service would call, select their service, and their information would be sent to the nearest technician.",
"Technicians would be available in the database along with a set service radius.",
"When a call comes in, we request that they select the service type (depending on the phone number called) and enter their zip code.",
"Using available technician locations, dispatch to the nearest available option.",
"Utilize separate phone app to update technician locations and availability as they move about their service area and perform work.",
"",
"For speed I used Twilio Studio to manage the logic of the IVR. It performed well, but we were locked into Twilio pricing as a result, becoming a concern after prolonged use.",
"If remade, I would use Asterisk for call logic and Twilio for SMS functionality.",
"We ended up using this to replace late-night live dispatching, though not during the day, as the conversion rate with this system is not as high as with a live dispatcher. I attribute this to some people's preference against automated systems."
],
    videoUrl: false,
    mainImage:
      `${settings.BASEURL}/media/AutomaticDispatcherMain.png`,
  },
  {
    id: 10, //have//GOOD ENOUGH
    title: "Field Technician Andriod iOS App",
    description:
      "React Native app supporting iOS and Android. Technicians accept and close jobs, manage inventory, accept company rules, view payments and more. Realtime background location tracking. Features JSON Web Token secured API requests and a Python Flask backend for computationally heavy operations.",
    tags: [
      "Service Industry",
      "Mobile App",
      "Location Tracking",
      "Background Tasks",
      "Inventory Management",
      "Remote Worker Management",
      "Payment Reports",
      "Image Upload",
      "JSON Web Tokens",
      "Secure API",
      "User Authentication",
      "Text Messaging",
      "React Native",
      "React Native Paper",
      "Flask",
      "MySQL",
      "Twilio",
      "Android",
      "iOS",
    ],
    thumbnail:
      `${settings.BASEURL}/media/TechnicianAppThumbnail.gif`,
    technologies: ["React Native", "Firebase", "Map APIs"],
    projectGoals: [
      "Work on iOS and Android",
      "Feature OTA updates so updates can be made without publishing to the App/Play Store.",
      "Technicians view, accept, and close jobs.",
      "Technicians have different permissions.",
      "Manage technician inventory.",
      "Technicians with permission manage inventory, with ability to create new inventory.",
      "Technician image upload for jobs, inventory, images, etc..",
      "Technician pay reports and historical reports.",
      "Call forwarding: Technician does not have customer number and all calls are made from a recorded line.",
      "Technician Rule Acceptance: Technician must be able to accept rules.",
      "Technician active status: Technicians can set their availability status to indicate availability to work.",
    ],
    role: "Mobile Developer",
    contributions:
      "Implemented real-time location tracking and job management system.",
    challenges: "Accurate tracking of technician locations.",
    solutions: "Utilized map APIs with optimized location updates.",
    features: [
      "Monitors technicians locations.",
      "Tracks technician inventory.",
      "Logs technician availability.",
      "Alerts technicians by text message.",
      "All calls recorded.",
      "Tracks technician time on jobs.",
      "Protected image upload endpoints.",
      "Rule acceptance logging.",
      "Individual technician and job permissions",
    ],
    architecture: "Client-server architecture with real-time data sync.",
    testing: "Manual testing with simulation tools.",
    outcomes: "Improved job dispatch efficiency by 35%.",
    lessonsLearned: "Mastered usage of mobile map integrations.",
    futurePlans: "Implement offline access features.",
    images: [],
    link: ["https://github.com/example/react-native-technician"],
    details: [
      "I had a need to manage multiple locksmith field technicians. I needed a complete solution to manage jobs, their inventory, customer interactions, and rule acceptance.",
      "I decided to implement these features in a React Native app, versus a web portal, for reliable access to GPS data (including spoofing attempts) for accurate dispatching.",
      "Background tasks and monitoring location across devices when the app was not used proved to be one of the more difficult aspects of this app, however was essential. It remains something that needs to be updated periodically with device updates, as there are sometimes breaking changes.",
      "The app was a success. Technicians found the interface intuitive and were able to use it with minimal instruction after initial setup.",
    ],
    featured: true,
    videoUrl:
      `${settings.BASEURL}/media/TechnicianApp.mp4`,
    mainImage: "",
  },

  {
    id: 30, //GOOD ENOUGH
    title: "Rolling Google Ads Location Updater",
    description:
      "Automatically updates Google Ads campaign locations based on GPS data received from the Technician App(separate). Helps ensure that individualized Google Ad campaigns are always targeted near field technician.",
    tags: [
      "Service Industry",
      "Remote Worker Management",
      "Workflow Automation",
      "Background Tasks",
      "Location Tracking",
      "React Native",
      "Secure API",
      "Python",
      "Flask",
      "MySQL",
      "Twilio",
      "Android",
      "iOS",
    ],
    thumbnail: `${settings.BASEURL}/media/rollingServiceAreaUpdaterThumb.png`,
    projectGoals: ["Receive location updates from separate technician app containing lat/long.",
"Determine if enough of a change has been made to require a Google Ads campaign location update.",
"Create list of city names and zip codes within a radius based on lat/long values.",
"Update Google Ads with new locations"],
    technologies: ["Google Ads API", "Node.js", "GPS APIs"],
    role: "Full-Stack Developer",
    contributions: "Developed location-based updates.",
    challenges: "Handling real-time location changes.",
    solutions: "Integrated GPS tracking and Google Ads APIs.",
    features: [
"Custom API to communicate with Google Ads.",
"City and Zip Code lookup based on lat/long and provided radius.",
"Custom Google Ads API to interact with the account, as they did not have an API key."
    ],
    architecture: "Client-server architecture.",
    testing: "Location accuracy tests.",
    outcomes: "Improved ad relevance.",
    lessonsLearned: "Enhanced skills in location tracking.",
    futurePlans: "Add more location filters.",
    images: [],
    link: ["https://github.com/example/rollingserviceupdater"],
    details: ["We had a need to update Google Ads campaigns based on technicians' locations.",
"Field technicians were driving around, and Google Ads campaigns were created for each one.",
"When a technician entered new areas, their Google Ads campaign location would update so that they would get jobs in their immediate vicinity.",
"This proved highly effective. Job success rate increased due to faster arrival times and technician's were routed more effectively."],
    videoUrl: false,
    mainImage: `${settings.BASEURL}/media/rollingServiceAreaUpdaterMain.png`,
  },
];
