import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import { Box, Button, Typography, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const CookieNotification = () => {
  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    const cookieConsent = Cookies.get('cookieConsent');
    if (!cookieConsent) {
      setShowBanner(true);
    }
  }, []);

  const handleConsent = (consentType) => {
    Cookies.set('cookieConsent', consentType, { expires: 365 });
    setShowBanner(false);
  };

  return (
    showBanner && (
        <Box
        sx={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          right: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.8)',
          color: 'white',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '16px',
          zIndex: 1000,
          boxShadow: '0 -2px 6px rgba(0,0,0,0.2)',
        }}
      >
        <IconButton
          sx={{
            position: 'absolute',
            top: 8,
            left: 8,
            color: 'white',
          }}
          onClick={() => handleConsent('dismissed')}
        >
          <CloseIcon />
        </IconButton>
        <Typography
          sx={{
            flex: 1,
            paddingLeft: '40px',
            textAlign: 'center',
            margin: '5px'

          }}
        >
          We use cookies to enhance your experience. By using our site, you acknowledge our cookie usage.
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: '8px' }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleConsent('accepted')}
          >
            Accept
          </Button>
        </Box>
      </Box>
    )
  );
};

export default CookieNotification;
