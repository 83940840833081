import React from "react";
import {
  Box,
  Typography,
  Button,
  Container,
  Grid,
  Avatar,
} from "@mui/material";
import { Link } from "react-router-dom";
import { RocketLaunch, Handshake, Code, Build } from "@mui/icons-material";
import * as settings from "../settings";

const LandingPage = () => {
  const emphasisTextColor = "#1976d2";

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh",
        background: `linear-gradient(135deg, rgba(227, 242, 253, 0.85), rgba(187, 222, 251, 0.85)), url(${settings.BASEURL}/vscode.png)`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        padding: "20px",
        textAlign: "center",
      }}
    >
      <Container
        maxWidth="sm"
        sx={{
          backgroundColor: "#ffffff",
          padding: 4,
          borderRadius: "16px",
          boxShadow: "0 8px 20px rgba(0, 0, 0, 0.15)",
        }}
      >
        <Grid container spacing={4}>
          {[
            { icon: Code, label: "Developer" },
            { icon: RocketLaunch, label: "Starter" },
            { icon: Handshake, label: "Partner" },
            { icon: Build, label: "Maker" },
          ].map((item, index) => (
            <Grid item xs={6} md={3} key={index}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    width: 50,
                    height: 50,
                    borderRadius: "50%",
                    backgroundColor: "#e0f7fa",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    mb: 1,
                    transition: "transform 0.5s, background-color 0.3s",
                    "&:hover": {
                      transform: "rotateY(360deg)",
                      backgroundColor: "#0d47a1",
                    },
                  }}
                >
                  <item.icon
                    sx={{
                      fontSize: "2rem",
                      color: "#0d47a1",
                      transition: "color 0.3s",
                      "&:hover": {
                        color: "#ffffff",
                      },
                    }}
                  />
                </Box>
                <Typography
                  variant="h5"
                  gutterBottom
                  sx={{
                    color: "#0d47a1",
                    fontWeight: "bold",
                    textShadow: "1px 1px 3px rgba(0,0,0,0.2)",
                  }}
                >
                  {item.label}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
        <Box sx={{ textAlign: "center", mb: 4, mt: 4 }}>
          <Avatar
            alt="Matthew Kontos"
            src={`${settings.BASEURL}/media/matthew.png`}
            sx={{
              width: 120,
              height: 120,
              mx: "auto",
              mb: 2,
              border: "4px solid #0d47a1",
            }}
          />
          <Typography
            variant="h4"
            component="h1"
            sx={{ fontWeight: "bold", color: "#0d47a1" }}
          >
            Matthew Kontos
          </Typography>
          <Typography
            variant="body1"
            sx={{ fontStyle: "italic", color: "#424242", mb: 2 }}
          >
            Chicago, US
          </Typography>
          <Typography
            variant="h5"
            color="textSecondary"
            paragraph
            sx={{
              fontSize: { xs: "1rem", md: "1.5rem" },
              lineHeight: 1.6,
            }}
          >
            With over a <span style={{ color: emphasisTextColor }}><b>decade of full-stack development</b></span>, automation, and startup leadership experience, I have successfully delivered <span style={{ color: emphasisTextColor }}><b>end-to-end systems</b></span>, developed workflows for rapid prototyping, and produced <span style={{ color: emphasisTextColor }}><b>$1.5M+ sales in self-started projects</b></span>.
          </Typography>
        </Box>
        <Container maxWidth="md" sx={{ mb: 6 }}>
          <Box mt={4}>
            <Grid container spacing={2} justifyContent="center">
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  component={Link}
                  to="/projects"
                  sx={{
                    transition: "all 0.3s ease-in-out",
                    padding: "10px 20px",
                    borderRadius: "8px",
                    boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
                    "&:hover": {
                      backgroundColor: "#0c63e4",
                      transform: "translateY(-3px)",
                    },
                  }}
                >
                  View My Projects
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  color="primary"
                  size="large"
                  component={Link}
                  to="/about"
                  sx={{
                    transition: "all 0.3s ease-in-out",
                    padding: "10px 20px",
                    borderRadius: "8px",
                    boxShadow: "0 4px 12px rgba(0, 0, 0, 0.05)",
                    "&:hover": {
                      borderColor: "#0c63e4",
                      backgroundColor: "#e3f2fd",
                      transform: "translateY(-3px)",
                    },
                  }}
                >
                  About Me
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Container>
        <Box sx={{ maxWidth: "600px", margin: "auto", mt: 4 }}>
          <Typography
            variant="h5"
            color="textSecondary"
            paragraph
            sx={{
              fontSize: { xs: "1rem", md: "1.5rem" },
              lineHeight: 1.6,
            }}
          >
            I work best solving challenges through collaboration and creating <span style={{ color: emphasisTextColor }}><b>results-driven solutions</b></span> that solve real world problems.
          </Typography>
          <Typography
            variant="h5"
            color="textSecondary"
            paragraph
            sx={{
              fontSize: { xs: "1rem", md: "1.5rem" },
              lineHeight: 1.6,
            }}
          >
            Whether it's<span style={{ color: emphasisTextColor }}><b> building MVPs from the ground up</b></span> or streamlining existing business operations, my focus remains on delivering <span style={{ color: emphasisTextColor }}><b>excellence</b></span> and creating <span style={{ color: emphasisTextColor }}><b>value</b></span> for clients and users alike.
          </Typography>
          <Typography
            variant="h5"
            color="textSecondary"
            paragraph
            sx={{
              fontSize: { xs: "1rem", md: "1.5rem" },
              lineHeight: 1.6,
            }}
          >
            This site was built using React and Material UI to showcase the flexibility of the technical solutions that I can provide.
          </Typography>
          <Typography
            variant="h5"
            color="textSecondary"
            paragraph
            sx={{
              fontSize: { xs: "1rem", md: "1.5rem" },
              lineHeight: 1.6,
            }}
          >
            I work independently and do not hire subcontractors.
          </Typography>
          <Grid container spacing={2} justifyContent="center">
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                size="large"
                component={Link}
                to="/contact"
                sx={{
                  transition: "all 0.3s ease-in-out",
                  padding: "10px 20px",
                  borderRadius: "8px",
                  boxShadow: "0 4px 12px rgba(0, 0, 0, 0.05)",
                  "&:hover": {
                    borderColor: "#0c63e4",
                    backgroundColor: "#e3f2fd",
                    transform: "translateY(-3px)",
                  },
                }}
              >
                Contact
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export default LandingPage;
