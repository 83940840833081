import React, { useState } from "react";
import {
  Typography,
  Box,
  Grid,
  Avatar,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Card,
} from "@mui/material";
import Lightbox from "react-awesome-lightbox";
import * as settings from "../settings";

const images = [
  `${settings.BASEURL}/media/matthew1.png`,
  `${settings.BASEURL}/media/matthew2.png`,
];

const skills = {
  "Programming Languages": [
    "Python",
    "Javascript",
    "PHP",
    "Shell Scripts",
    "Batch Scripts",
    "Typescript (Upcoming)",
  ],
  "Frameworks & Libraries": [
    "React",
    "React Native",
    "Node JS",
    "Flask",
    "Express",
    "Material UI",
    "Bootstrap",
    "Redux",
  ],
  "Databases & Backends": [
    "MySQL",
    "Asterisk",
    "Postfix",
    "Dovecot",
    "MongoDB (Upcoming)",
  ],
  "Tools & Platforms": [
    "Docker",
    "Containerization",
    "Virtual Machines",
    "Git",
    "cURL",
  ],
  "Web Technologies": ["HTML5", "CSS", "Apache"],
  "Generative AI": [
    "OpenAI API (GPT Models, DALL·E, Whisper)",
    "Locally Run AI Models",
    "Ollama",
  ],
  "Operating Systems": ["Windows OS", "Debian Based Linux Distros"],
  "Payment Processing": ["Credit Card Processing", "Stripe", "Square"],
  "Web Services and APIs": [
    "REST",
    "Twilio",
    "Sendgrid",
    "Mailgun",
    "Mailchimp",
    "PayPal",
    "AWS",
    "Google Cloud",
    "Google Maps",
    "OpenStreetMap",
    "Zoom Video",
  ],
};

const AboutMePage = () => {
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);
  const [lightboxImages, setLightboxImages] = useState([]);
  const [lightboxIndex, setLightboxIndex] = useState(0);

  const handleImageClick = (index, imageLinks) => {
    // Ensure `imageLinks` is an array
    setLightboxImages(imageLinks);
    setLightboxIndex(index);
    setIsLightboxOpen(true);
  };

  return (
    <Box sx={{ padding: "2rem" }}>
      <Paper elevation={3} sx={{ padding: 3, marginBottom: 2 }}>
        <Grid container spacing={4}>
          {/* Left Half */}
          <Grid item xs={12} md={6}>
            <Box sx={{ textAlign: "center", mb: 4 }}>
              <Avatar
                alt="Matthew Kontos"
                src={`${settings.BASEURL}/media/matthew.png`}
                sx={{
                  width: 120,
                  height: 120,
                  mx: "auto",
                  mb: 2,
                  border: "4px solid #0d47a1",
                }}
              />
              <Typography
                variant="h4"
                component="h1"
                sx={{ fontWeight: "bold", color: "#0d47a1" }}
              >
                Matthew Kontos
              </Typography>
              <Typography
                variant="body1"
                sx={{ fontStyle: "italic", color: "#424242", mb: 2 }}
              >
                Chicago, US
              </Typography>
            </Box>
            <Typography variant="body1" paragraph>
              Hello, my name is Matthew Kontos, a developer, starter, partner,
              and maker. I have been self-employed for the last 10 years and was
              working on full-stack technical projects long before that. I began
              my first startup at the age of 25 and generated over $1.5M in
              sales. I have since desired to help businesses automate processes
              and improve through custom software.
            </Typography>
            <Typography variant="body1" paragraph>
              Using automation, I have started companies and operated local and
              remote workers in service industries including real estate,
              locksmith, residential cleaning, and more.
            </Typography>
            <Typography variant="body1" paragraph>
              I am also a licensed real estate broker and licensed locksmith
              agency owner in Illinois.
            </Typography>
            <Typography variant="body1" paragraph>
              Over the years, I have developed the interest and ability to
              translate business needs into automated tools. I have worked
              extensively to create time-saving and efficiency-boosting tools
              for both my personal businesses and for clients.
            </Typography>
            <Typography variant="body1" paragraph>
              In my rare moments of spare time, I enjoy exploring the latest
              technology, a passion that often sparks new ideas to improve my
              work. Cooking and playing musical instruments provide a creative
              outlet, while hiking, traveling, and kayaking help me bring fresh
              perspectives to the challenges I face in my work.
            </Typography>
          </Grid>

          {/* Right Half */}
          <Grid item xs={12} md={6}>
            <Typography variant="h4" gutterBottom>
              Skills
            </Typography>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Category</TableCell>
                    <TableCell>Skills</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.entries(skills).map(([category, items]) => (
                    <TableRow key={category}>
                      <TableCell>
                        <b>{category}</b>
                      </TableCell>
                      <TableCell>{items.join(", ")}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            {/* Project Images */}
            {images?.length > 0 && (
              <Card variant="outlined" sx={{ mb: 4, p: 2 }}>
                <Box display="flex" flexWrap="wrap" gap={2}>
                  {images.map((image, index) => (
                    <img
                      key={index}
                      src={image}
                      alt={`Screenshot ${index + 1}`}
                      style={{
                        maxWidth: "250px",
                        maxHeight: "125px",
                        cursor: "pointer",
                        boxShadow: "3px 3px 3px 3px rgb(0 0 0 / 20%)",
                      }}
                      onClick={() => handleImageClick(index, images)}
                    />
                  ))}
                </Box>
              </Card>
            )}
          </Grid>
        </Grid>
      </Paper>
      {/* Render Lightbox if open */}
      {isLightboxOpen && (
        <Lightbox
          images={lightboxImages.map((src) => ({ url: src }))}
          startIndex={lightboxIndex}
          onClose={() => setIsLightboxOpen(false)}
        />
      )}
    </Box>
  );
};

export default AboutMePage;
