import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Grid,
  Chip,
  Typography,
  Box,
  Card,
  CardContent,
  CardMedia,
  Button,
  Pagination,
} from "@mui/material";
import "react-awesome-lightbox/build/style.css";
import Lightbox from "react-awesome-lightbox";
import { projectData } from "../projects";
import IntensityIndicator from "../components/IntensityIndicator";

const ProjectsPage = () => {
  const [selectedTags, setSelectedTags] = useState([]);
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [lightboxImage, setLightboxImage] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const projectsPerPage = 6; // Number of projects per page

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const filteredProjects =
    selectedTags.length > 0
      ? projectData.filter((project) =>
          selectedTags.some((tag) => project.tags.includes(tag))
        )
      : projectData;

  const uniqueTags = [
    ...new Set(projectData.flatMap((project) => project.tags)),
  ];

  const handleTagClick = (tag) => {
    setSelectedTags((prevSelectedTags) =>
      prevSelectedTags.includes(tag)
        ? prevSelectedTags.filter((t) => t !== tag)
        : [...prevSelectedTags, tag]
    );
  };

  // Pagination logic
  const indexOfLastProject = currentPage * projectsPerPage;
  const indexOfFirstProject = indexOfLastProject - projectsPerPage;
  const currentProjects = filteredProjects.slice(
    indexOfFirstProject,
    indexOfLastProject
  );
  const totalPages = Math.ceil(filteredProjects.length / projectsPerPage);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const featuredProjects = projectData.filter((project) => project.featured);

  return (
    <Box sx={{ padding: 3 }}>
      <Typography variant="h4" gutterBottom>
        Projects
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={5}>
          <Typography style={{ margin: "15px" }}>
            Aside from <b>this site</b>, below are some projects that I have{" "}
            <b>created entirely from start to finish</b>. This includes
            requirements gathering, UI/UX design, data modeling, schema design,
            front/backend coding, testing, and deployment.
          </Typography>
          <Typography style={{ margin: "15px" }}>
            These examples are meant to showcase the breadth of my work and
            desire to create solutions for real world problems. Many, but not
            all, of these projects have been implemented in my personal startup
            efforts. I have included some client samples as well.
          </Typography>
          <Typography style={{ margin: "15px" }}>
            I attempted to create this site for both technical and non-technical
            visitors. Have questions about my work? Contact me—I’d be happy to
            share insights.
          </Typography>
        </Grid>

        <Grid item xs={12} md={7}>
          <Box sx={{ marginBottom: 2}}>
            <Box
              sx={{
                display: "flex",
                gap: 1,
                flexWrap: "wrap",
                border: "1px solid #ccc",
                padding: 2,
              }}
            >
              <Typography style={{ width: "100%" }} variant="subtitle1">
                Filter by multiple tags:
              </Typography>
              {uniqueTags.map((tag) => (
                <Chip
                  key={tag}
                  label={tag}
                  color={selectedTags.includes(tag) ? "primary" : "default"}
                  onClick={() => handleTagClick(tag)}
                  clickable
                />
              ))}
            </Box>
          </Box>
        </Grid>
      </Grid>

      {/* Only show featured projects if no tags are selected */}
      {selectedTags.length === 0 && featuredProjects.length > 0 && currentPage === 1 && (
        <Box
          sx={{
            display: "flex",
            gap: 1,
            flexWrap: "wrap",
            marginTop: 1,
            border: "1px solid #ccc",
            padding: 2,
            marginBottom: 3,
          }}
        >
          <Grid container spacing={3}>
            {" "}
            {/* Consistent spacing */}
            {featuredProjects.map((project) => (
              <Grid item key={project.id} xs={12} sm={6} md={4}>
                <Link
                  to={`/projects/${project.title.replace(/\s+/g, "-")}`}
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  <Card
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      height: "100%" /* Ensures uniform height */,
                      cursor: "pointer",
                      position: "relative",
                    }}
                  >
                    <CardMedia
                      component="img"
                      height="140"
                      image={project.thumbnail}
                      alt={project.title}
                      sx={{ objectFit: "cover" }}
                    />
                    <Chip
                      label="Featured"
                      color="secondary"
                      sx={{
                        position: "absolute",
                        top: 8,
                        left: 8,
                        zIndex: 1,
                      }}
                    />
                    <CardContent
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start", // Ensures alignment at the top
                        height: "100%", // Forces cards to be the same height
                      }}
                    >
                      <Typography variant="h6">{project.title}</Typography>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        gutterBottom
                      >
                        {project.description}
                      </Typography>
                      <Typography color="primary" variant="body">
                        Details
                        </Typography>
                      <Box
                        sx={{ marginTop: 1, display: "flex", flexWrap: "wrap" }}
                      >
                        {project.tags.map((tag) => (
                          <Chip
                            key={tag}
                            label={tag}
                            size="small"
                            sx={{ margin: "5px" }}
                          />
                        ))}
                      </Box>
                    </CardContent>
                  </Card>
                </Link>
              </Grid>
            ))}
          </Grid>
        </Box>
      )}

      <Grid container spacing={4}>
        {currentProjects.map((project) => (
          <Grid item key={project.id} xs={12} sm={6} md={4}>
            <Link
              to={`/projects/${project.title.replace(/\s+/g, "-")}`}
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <Card
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  height: "100%",
                  cursor: "pointer",
                  position: "relative",
                }}
              >
                <CardMedia
                  component="img"
                  height="140"
                  image={project.thumbnail}
                  alt={project.title}
                  style={{ cursor: "pointer" }}
                />
                {project.featured && (
                  <Chip
                    label="Featured"
                    color="secondary"
                    sx={{
                      position: "absolute",
                      top: 8,
                      left: 8,
                      zIndex: 1,
                      margin: "5px",
                    }}
                  />
                )}
                <CardContent
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    height: "100%", // Forces cards to be the same height
                  }}
                >
                  <Typography variant="h6">{project.title}</Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    gutterBottom
                  >
                    {project.description}
                  </Typography>
                  <Typography color="primary" variant="body">
                        Details
                        </Typography>
                  <Box sx={{ marginTop: 1 }}>
                    {project.tags.map((tag) => (
                      <Chip
                        key={tag}
                        label={tag}
                        size="small"
                        style={{ margin: "5px" }}
                      />
                    ))}
                  </Box>
                </CardContent>
              </Card>
            </Link>
          </Grid>
        ))}
      </Grid>

      {/* Pagination */}
      <Box sx={{ display: "flex", justifyContent: "center", marginTop: 4 }}>
        <Pagination
          count={totalPages}
          page={currentPage}
          onChange={handlePageChange}
          color="primary"
        />
      </Box>
    </Box>
  );
};

export default ProjectsPage;
