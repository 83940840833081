import React from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
// Import the vsc-dark-plus theme
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';

const CodeSnippet = ({ language, code, filename }) => {
  return (
    <div style={{ border: '1px solid #ccc', borderRadius: '4px', maxHeight: "500px", overflow: 'auto' }}>
      {filename && (
        <div style={{ 
          backgroundColor: '#2d2d2d', 
          color: '#ffffff', 
          padding: '8px', 
          fontSize: '0.9rem', 
          fontWeight: 'bold', 
          borderBottom: '1px solid #ccc' 
        }}>
          {filename} ({language})
        </div>
      )}
      <SyntaxHighlighter
        language={language}
        style={vscDarkPlus}
        showLineNumbers
        wrapLongLines
      >
        {code}
      </SyntaxHighlighter>
    </div>
  );
};

export default CodeSnippet;
