import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
    APIExplorerSample,
    APICallerSample
  } from "../sourceCodeSamples/sourceCodeSamples";
import CodeSnippet from "./CodeSnippet";

export default function APIExplorerComponents() {
  return (
    <div style={{ maxHeight: '80%', overflowY: 'auto' }}>
      {/* First Accordion: APIExplorer.js */}
      <Accordion>
        
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="api-explorer-content"
          id="api-explorer-header"
        >
          <Typography>/src/pages/APIExplorer.js</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {/* Scrollable container */}
          <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
            <CodeSnippet code={APIExplorerSample} language="javascript" filename="/src/pages/APIExplorer.js"/>
          </div>
        </AccordionDetails>
      </Accordion>

      {/* Second Accordion: APICaller.js */}
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="api-caller-content"
          id="api-caller-header"
        >
          <Typography>/src/components/APICaller.js</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
          <CodeSnippet code={APICallerSample} language="javascript" filename="/src/components/APICaller.js"/>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
